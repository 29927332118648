// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetClientSitesForManagerSiteRequest,
  GetClientSitesForManagerSiteReply
} from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";

// Lists manager-client links for specified manager site.
export async function getClientSitesForManagerSite(managerSiteAlias) {
  if (!managerSiteAlias) {
    return new GetClientSitesForManagerSiteReply();
  }

  const getRequest = proto.set(new GetClientSitesForManagerSiteRequest(), {
    managerSiteAlias
  });

  const response = await GRPCWebClient.getClientSitesForManagerSite(getRequest);
  return response?.toObject();
}
