import React from "react";
import { generatePath } from "react-router-dom";

import {
  CHECKED_COL_WIDTH,
  CHECKED_COL,
  ACCOUNT_NAME_COL,
  GOOGLE_ADS_COL,
  BILLING_STATUS_COL,
  CAMPAIGNS_COL,
  AACOS_RANGE_COL
} from "./AccountsTable";

import {
  CAMPAIGN_ID_QUERY_PARAM,
  ActiveCampaignName,
  InactiveCampaignName
} from "../../components/CampaignsTableRow";

import {
  DATE_RANGE_CUSTOM_START_URL_PARAM,
  DATE_RANGE_CUSTOM_END_URL_PARAM,
  DATE_RANGE_NAME_URL_PARAM,
  DATE_RANGE_COMPARE_TO_URL_PARAM
} from "ExtensionV2/redux/audit";
import {
  AMPD_ROOT,
  CAMPAIGNS_PATH,
  DASHBOARD_PATH
} from "ExtensionV2/ExtensionV2";
import {
  COLUMN_DATA_KEYS,
  formatMetricColumnValue,
  getMetricColumnCompareInfo,
  renderFormattedValue,
  InlineLoadingSpinner
} from "../../components/MetricColumns";
import {
  DataTableRow,
  DataTableFreezeLeftCell,
  DataTableMetricCell,
  DataTableRowCell
} from "../../components/AmpdDataTable";

function AccountsTableCampaignRow({
  clientSiteAlias,
  clientSiteLoading,
  campaignRowData,
  columns,
  rowIndex,
  metricsLoading,
  showFractions,
  isLastRow
}) {
  const campaignLink = (
    <LinkToClient
      clientSiteAlias={clientSiteAlias}
      campaignId={campaignRowData.campaignId}
    >
      {campaignRowData.name}
    </LinkToClient>
  );

  return (
    <DataTableRow
      key={rowIndex}
      style={{ fontSize: "small" }}
      isLastKeywordRow={isLastRow}
    >
      {columns.map(columnName => {
        switch (columnName) {
          case CHECKED_COL:
            return <DataTableFreezeLeftCell key={columnName} />;
          case ACCOUNT_NAME_COL:
            return (
              <DataTableFreezeLeftCell
                style={{ left: CHECKED_COL_WIDTH }}
                key={columnName}
                rowIndex={rowIndex}
              >
                {campaignRowData.status === "ENABLED" ? (
                  <ActiveCampaignName>{campaignLink}</ActiveCampaignName>
                ) : (
                  <InactiveCampaignName>{campaignLink}</InactiveCampaignName>
                )}
              </DataTableFreezeLeftCell>
            );
          case GOOGLE_ADS_COL:
            return (
              <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                {campaignRowData.status}
              </DataTableRowCell>
            );
          case BILLING_STATUS_COL:
            return <DataTableRowCell key={columnName} rowIndex={rowIndex} />;
          case CAMPAIGNS_COL:
            return <DataTableRowCell key={columnName} rowIndex={rowIndex} />;

          case AACOS_RANGE_COL: {
            if (clientSiteLoading || metricsLoading) {
              return (
                <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                  <InlineLoadingSpinner />
                </DataTableRowCell>
              );
            }

            const costCurrencyCode = campaignRowData.costCurrencyCode;
            const revenueCurrencyCode = campaignRowData.revenueCurrencyCode;

            const formattedValue = formatMetricColumnValue(
              COLUMN_DATA_KEYS.aacos,
              campaignRowData[COLUMN_DATA_KEYS.aacos],
              costCurrencyCode,
              revenueCurrencyCode,
              showFractions
            );

            // If we want to show comparison annotations, we need to compile
            // some information based on any compareMetrics value stored in
            // the data object.
            const compareInfo = getMetricColumnCompareInfo(
              COLUMN_DATA_KEYS.aacos,
              campaignRowData,
              costCurrencyCode,
              revenueCurrencyCode
            );

            return (
              <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                {renderFormattedValue(formattedValue, undefined, compareInfo)}
              </DataTableMetricCell>
            );
          }
          default: {
            if (clientSiteLoading || metricsLoading) {
              return (
                <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                  <InlineLoadingSpinner />
                </DataTableRowCell>
              );
            }

            const costCurrencyCode = campaignRowData.costCurrencyCode;
            const revenueCurrencyCode = campaignRowData.revenueCurrencyCode;

            const formattedValue = formatMetricColumnValue(
              columnName,
              campaignRowData[columnName],
              costCurrencyCode,
              revenueCurrencyCode,
              showFractions
            );

            // If we want to show comparison annotations, we need to compile
            // some information based on any compareMetrics value stored in
            // the data object.
            const compareInfo = getMetricColumnCompareInfo(
              columnName,
              campaignRowData,
              costCurrencyCode,
              revenueCurrencyCode
            );

            return (
              <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                {renderFormattedValue(formattedValue, undefined, compareInfo)}
              </DataTableMetricCell>
            );
          }
        }
      })}
    </DataTableRow>
  );
}

export function LinkToClient({ clientSiteAlias, campaignId, children }) {
  const searchParams = new URLSearchParams(window.location.search);

  let clientSiteUrl;
  if (campaignId) {
    clientSiteUrl = new URL(
      generatePath(`${AMPD_ROOT}/${CAMPAIGNS_PATH}`, {
        siteAlias: clientSiteAlias
      }),
      window.location.origin
    );
  } else {
    // Since there is no specific campaignId, link to the default path and let
    // the router decide whether to go to the Campaigns page or the Accounts page.
    clientSiteUrl = new URL(
      generatePath(`${DASHBOARD_PATH}`, {
        siteAlias: clientSiteAlias
      }),
      window.location.origin
    );
  }

  clientSiteUrl.searchParams.set(
    DATE_RANGE_CUSTOM_START_URL_PARAM,
    searchParams.get(DATE_RANGE_CUSTOM_START_URL_PARAM)
  );
  clientSiteUrl.searchParams.set(
    DATE_RANGE_CUSTOM_END_URL_PARAM,
    searchParams.get(DATE_RANGE_CUSTOM_END_URL_PARAM)
  );
  clientSiteUrl.searchParams.set(
    DATE_RANGE_NAME_URL_PARAM,
    searchParams.get(DATE_RANGE_NAME_URL_PARAM)
  );
  clientSiteUrl.searchParams.set(
    DATE_RANGE_COMPARE_TO_URL_PARAM,
    searchParams.get(DATE_RANGE_COMPARE_TO_URL_PARAM)
  );

  if (campaignId) {
    clientSiteUrl.searchParams.set(CAMPAIGN_ID_QUERY_PARAM, campaignId);
  }

  return (
    <a href={clientSiteUrl} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  );
}

export default AccountsTableCampaignRow;
