import {
  CreateGoogleAdsCampaignArgs,
  useCreateGoogleAdsCampaign
} from "ExtensionV2/queries/useCreateGoogleAdsCampaign";
import { Site } from "ExtensionV2/queries/useSession";
import React, { useEffect, useState } from "react";
import { Button, Message } from "semantic-ui-react";

import {
  CampaignSetupAction,
  CampaignSetupStage,
  CampaignSetupState,
  getCurrentCampaignAndSelectors,
  isValidCampaign,
  useCreateCampaignArgs
} from "./CampaignSetupPageState";
import { generatePath, useNavigate } from "react-router-dom-v5-compat";
import { extractErrorMessage } from "Common/errors/error";
import { connect } from "redux-zero/react";
import { actions } from "ExtensionV2/redux/store";
import { CampaignLaunchConfirmationModal } from "./CampaignLaunchConfirmationModal";
import { AMPD_ROOT, CAMPAIGNS_PATH } from "ExtensionV2/ExtensionV2";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";

type CampaignSetupPageControlsProps = {
  canProceed: boolean;
  currentStage: CampaignSetupState["stage"];
  dispatch: React.Dispatch<CampaignSetupAction>;
  state: CampaignSetupState;
  currentSite: Site;
  copyCampaign?: boolean;

  // these come from the Redux connect() below
  increaseAuditInfoRefreshCount?: () => void;
  refreshCampaignInfo?: (siteAlias: string, omitMetrics: boolean) => void;
};

export const PAUSING_AUTOMATION_CONFIG_ERROR =
  "Your campaign was created, however there was an error when setting up Ampd Protection. You can set up Ampd Protection in details pane on the Campaigns page.";

const _CampaignSetupPageControls = ({
  canProceed,
  currentStage,
  dispatch,
  state,
  currentSite,
  copyCampaign,
  increaseAuditInfoRefreshCount,
  refreshCampaignInfo
}: CampaignSetupPageControlsProps): JSX.Element => {
  const { siteAlias } = useSessionSite();

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const navigate = useNavigate();
  const [campaign] = getCurrentCampaignAndSelectors(state);

  const {
    args,
    isLoading: createCampaignArgsLoading,
    error: createCampaignArgsError
  } = useCreateCampaignArgs(currentSite, state);

  const {
    mutate: createGoogleCampaign,
    isLoading: isCreatingGoogleCampaign,
    error: createGoogleCampaignError,
    reset: resetCreateGoogleCampaignMutation
  } = useCreateGoogleAdsCampaign();

  useEffect(() => {
    resetCreateGoogleCampaignMutation();
  }, [state.currentReviewCampaignIndex, resetCreateGoogleCampaignMutation]);

  const handleClickNext = () => {
    const nextStage = currentStage + 1;
    if (nextStage in CampaignSetupStage) {
      dispatch({
        name: "UpdateStage",
        data: nextStage
      });
    }
  };

  const handleCreateCampaign = async (args: CreateGoogleAdsCampaignArgs) => {
    resetCreateGoogleCampaignMutation();

    createGoogleCampaign(
      {
        ...args,
        onCreateCampaign: reply => {
          if (reply?.campaignId) {
            dispatch({
              name: "UpdateCampaignId",
              data: {
                campaignId: reply.campaignId,
                index: state.currentReviewCampaignIndex
              }
            });
          }
        }
      },
      {
        onSuccess: () => {
          increaseAuditInfoRefreshCount?.();
          refreshCampaignInfo?.(currentSite.siteAlias, true);
        },
        onError: e => {
          console.error("Error creating Google Ad Campaign", e);
        },
        onSettled: () => {
          setConfirmationModalOpen(true);
        }
      }
    );
  };

  const dashboardPath = generatePath(
    `${AMPD_ROOT}/${CAMPAIGNS_PATH}?campaign=${campaign.campaignId}`,
    { siteAlias }
  );

  const nextButton = () => {
    if (currentStage !== CampaignSetupStage.Review) {
      return (
        <Button
          disabled={!(currentStage + 1 in CampaignSetupStage) || !canProceed}
          primary
          onClick={handleClickNext}
        >
          Next
        </Button>
      );
    } else if (
      state.currentReviewCampaignIndex === state.campaigns.length - 1 &&
      !!campaign.campaignId
    ) {
      return (
        <Button
          loading={isCreatingGoogleCampaign}
          primary
          onClick={() => navigate(dashboardPath)}
        >
          See Campaigns
        </Button>
      );
    } else if (campaign.campaignId) {
      return (
        <Button
          loading={isCreatingGoogleCampaign}
          primary
          onClick={() =>
            dispatch({
              name: "UpdateCurrentReviewCampaignIndex",
              data: state.currentReviewCampaignIndex + 1
            })
          }
        >
          Review Next Campaign
        </Button>
      );
    }

    const isValid = isValidCampaign(campaign);

    return (
      <Button
        disabled={!isValid}
        primary
        onClick={() => {
          if (!args) {
            return;
          }
          handleCreateCampaign(args);
        }}
        loading={createCampaignArgsLoading || isCreatingGoogleCampaign}
      >
        Launch Campaign
      </Button>
    );
  };

  const handleClickPrevious = () => {
    const prevStage = currentStage - 1;

    if (state.currentReviewCampaignIndex > 0) {
      dispatch({
        name: "UpdateCurrentReviewCampaignIndex",
        data: state.currentReviewCampaignIndex - 1
      });
    } else if (prevStage in CampaignSetupStage) {
      dispatch({
        name: "UpdateStage",
        data: prevStage
      });
    }
  };

  const hasError = !!createCampaignArgsError || !!createGoogleCampaignError;

  return (
    <>
      {confirmationModalOpen && (
        <CampaignLaunchConfirmationModal
          campaignSubmitError={extractErrorMessage(createGoogleCampaignError)}
          currentSite={currentSite}
          dispatch={dispatch}
          isLoading={createCampaignArgsLoading || isCreatingGoogleCampaign}
          onClose={() => setConfirmationModalOpen(false)}
          onResubmit={handleCreateCampaign}
          state={state}
        />
      )}
      <div
        style={{
          alignSelf: "flex-end",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {hasError && (
          <Message negative>
            <p
              style={{
                whiteSpace: "pre-wrap"
              }}
            >
              {extractErrorMessage(createCampaignArgsError) ||
                extractErrorMessage(createGoogleCampaignError) ||
                "There was a problem creating your campaign."}
            </p>
          </Message>
        )}
        <div
          style={{
            justifySelf: "flex-end",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}
        >
          {!copyCampaign && (
            <Button
              disabled={!(currentStage - 1 in CampaignSetupStage)}
              onClick={handleClickPrevious}
            >
              Previous
            </Button>
          )}

          {nextButton()}
        </div>
      </div>
    </>
  );
};

// This is necessary to refresh the performance page after creating a campaign, since that data
// is still in Redux Zero.
const CampaignSetupPageControls = connect(() => {
  return {};
}, actions)(_CampaignSetupPageControls);

// Lets just pretend this is not a connected component so we can get type checks on the props.
export default (CampaignSetupPageControls as unknown) as (
  props: CampaignSetupPageControlsProps
) => JSX.Element;
