import Immutable from "immutable";
import pLimit from "p-limit";

import { useEffect, useRef, useState } from "react";

import { randomString } from "Common/utils/strings";
import { getClientSitesForManagerSite } from "../grpc/getClientSitesForManagerSite";
import { extractErrorMessage } from "Common/errors/error";

// Shared limiter used for report queries so there are not too many queries from
// one user in flight at the same time.
const queryPromiseLimiterMap = {};

function useMultiSiteClientSites({
  siteAliases,
  onStart,
  onLoad,
  onFinish,
  queryGroup = "clientSites"
}) {
  let queryPromiseLimiter = queryPromiseLimiterMap[queryGroup];
  if (!queryPromiseLimiter) {
    queryPromiseLimiter = pLimit(10);
    queryPromiseLimiterMap[queryGroup] = queryPromiseLimiter;
  }

  // The full state of the hook will be updated asynchronously as different
  // sites return from their query.  It is important that all inner loop
  // calls to 'setHookState' pass a callback instead of a value based on this
  // 'hookState' variable, because otherwise some results will be lost.
  // The 'hookState' variable will be returned on each render but any number of
  // updates could happen between renders.
  const [hookState, setHookState] = useState({
    sitesLoadingSet: Immutable.Set(),
    sitesErrorMap: Immutable.Map(),
    clientSitesBySiteMap: Immutable.Map()
  });
  const queryIdRef = useRef(null);

  useEffect(() => {
    let mounted = true;

    // Don't continue with previous queries that haven't been started yet.
    queryPromiseLimiter.clearQueue();

    // Let's create a random id to identify this set of queries.
    const queryId = randomString(16);
    queryIdRef.current = queryId;

    // Update the hook state directly at the start.
    setHookState({
      sitesLoadingSet: Immutable.Set(siteAliases),
      sitesErrorMap: Immutable.Map(),
      clientSitesBySiteMap: Immutable.Map()
    });
    if (siteAliases.length === 0) {
      return () => (mounted = false);
    }

    if (onStart) {
      onStart(siteAliases);
    }

    const collectClientSites = () =>
      siteAliases.map(siteAlias =>
        queryPromiseLimiter(async () => {
          if (!mounted || queryIdRef.current !== queryId) {
            return;
          }

          let loadErr = null;
          let clientSites = [];
          getClientSitesForManagerSite(siteAlias)
            .then(({ clientSitesList }) => {
              clientSites = clientSitesList;
            })
            .catch(err => {
              loadErr = err;
              console.error(extractErrorMessage(err));
            })
            .finally(() => {
              setHookState(state => {
                let {
                  sitesLoadingSet,
                  sitesErrorMap,
                  clientSitesBySiteMap
                } = state;

                sitesLoadingSet = sitesLoadingSet.remove(siteAlias);
                if (loadErr) {
                  sitesErrorMap = sitesErrorMap.set(siteAlias, loadErr);
                }
                clientSitesBySiteMap = clientSitesBySiteMap.set(
                  siteAlias,
                  clientSites
                );

                return {
                  sitesLoadingSet,
                  sitesErrorMap,
                  clientSitesBySiteMap
                };
              });

              if (mounted && onLoad) {
                onLoad({
                  siteAlias,
                  error: loadErr
                });
              }
            });
        })
      );

    Promise.all(collectClientSites())
      .then(() => {
        if (mounted && onFinish) {
          onFinish();
        }
      })
      .catch(err => {
        console.error(err);
        if (mounted && onFinish) {
          onFinish(err);
        }
      });

    return () => (mounted = false);
  }, [siteAliases, onStart, onLoad, onFinish, queryPromiseLimiter]);

  return hookState;
}

export default useMultiSiteClientSites;
