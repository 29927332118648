import React, { useContext } from "react";
import { connect } from "redux-zero/react";

import { actions } from "ExtensionV2/redux/store";
import {
  CampaignsPageWrapper,
  CampaignControlsWrapper
} from "../CampaignsPage/CampaignsPageRenderer";
import GlobalDatePicker from "ExtensionV2/components/GlobalDatePicker";
import AccountsTable, {
  ALL_ACCOUNTS_COLUMNS,
  DEFAULT_ACCOUNTS_COLUMNS,
  UNHIDEABLE_ACCOUNTS_COLUMNS
} from "./AccountsTable";
import { useAmpdDataTableOptions } from "../../components/AmpdDataTableOptions";
import {
  getStoredAccountsTableDataColumns,
  resetAllAccountsTableOptions,
  setStoredAccountsTableDateColumns
} from "Common/utils/savedTablePreferences";

import { useSession } from "../../queries/useSession";
import { WantsAmpdProOperatorFeaturesContext } from "../../index";

export const ACCOUNTS_DASHBOARD_GA_CATEGORY = "Ampd: Accounts Dashboard";

function _AccountsPage({ dateRangeStartDate, dateRangeEndDate, compareTo }) {
  const {
    currentSite,
    clientSitesList,
    user,
    updateSiteManagerLinkClientLabelsMutation,
    invalidateSessionQuery
  } = useSession();
  const { siteAlias } = currentSite;
  const { isCurrentSiteAdmin, isAmpdOperator, userEmail } = user;

  const {
    showFractions,
    showUnconvertedRevenue,
    excludeAmazonLagPeriod,
    selectableOptionsMap,
    selectedColumns,
    handleSelectColumnOption,
    handleResetTableOptions,
    handleToggleShowFraction,
    handleToggleShowUnconvertedRevenue,
    handleToggleExcludeAmazonLagPeriod
  } = useAmpdDataTableOptions({
    allColumns: ALL_ACCOUNTS_COLUMNS,
    defaultColumns: DEFAULT_ACCOUNTS_COLUMNS,
    unhideableColumns: UNHIDEABLE_ACCOUNTS_COLUMNS,
    getStoredDataTableColumns: getStoredAccountsTableDataColumns,
    setStoredDataTableColumns: setStoredAccountsTableDateColumns,
    resetAllDataTableOptions: resetAllAccountsTableOptions
  });

  const [wantAmpdProOperatorFeatures] = useContext(
    WantsAmpdProOperatorFeaturesContext
  );

  return (
    <CampaignsPageWrapper>
      {/* Filters */}
      <CampaignControlsWrapper>
        <GlobalDatePicker
          excludeAmazonLagPeriod={excludeAmazonLagPeriod}
          offerCompare={true}
        />
      </CampaignControlsWrapper>
      <AccountsTable
        managerSiteAlias={siteAlias}
        isSiteAdmin={isCurrentSiteAdmin}
        isAmpdOperator={isAmpdOperator}
        userEmail={userEmail}
        clientSites={clientSitesList}
        dateRangeStartDate={dateRangeStartDate}
        dateRangeEndDate={dateRangeEndDate}
        compareTo={compareTo}
        showFractions={showFractions}
        showUnconvertedRevenue={showUnconvertedRevenue}
        showHubspotLinks={isAmpdOperator && wantAmpdProOperatorFeatures}
        excludeAmazonLagPeriod={excludeAmazonLagPeriod}
        selectableOptionsMap={selectableOptionsMap}
        selectedColumns={selectedColumns}
        handleSelectColumnOption={handleSelectColumnOption}
        handleResetTableOptions={handleResetTableOptions}
        handleToggleShowFraction={handleToggleShowFraction}
        handleToggleShowUnconvertedRevenue={handleToggleShowUnconvertedRevenue}
        handleToggleExcludeAmazonLagPeriod={handleToggleExcludeAmazonLagPeriod}
        updateClientLabels={updateSiteManagerLinkClientLabelsMutation.mutate}
        invalidateSessionQuery={invalidateSessionQuery}
      />
    </CampaignsPageWrapper>
  );
}

const AccountsPage = connect(state => {
  const dateRangeStartDate = state.dataStartDate;
  const dateRangeEndDate = state.dataEndDate;
  const compareTo = state.dataCompareTo;

  return {
    dateRangeStartDate,
    dateRangeEndDate,
    compareTo
  };
}, actions)(_AccountsPage);

export default AccountsPage;
