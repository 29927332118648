import { CreateGoogleAdsCampaignArgs } from "ExtensionV2/queries/useCreateGoogleAdsCampaign";
import { Site } from "ExtensionV2/queries/useSession";
import React from "react";
import { generatePath, useNavigate } from "react-router-dom-v5-compat";
import { Button, Icon, Modal, Message, Loader } from "semantic-ui-react";
import { extractErrorMessage } from "Common/errors/error";
import {
  CampaignSetupAction,
  CampaignSetupState,
  getCurrentCampaignAndSelectors,
  useCreateCampaignArgs
} from "./CampaignSetupPageState";
import { pluralize } from "Common/utils/strings";
import { PAUSING_AUTOMATION_CONFIG_ERROR } from "./CampaignSetupPageControls";
import { AMPD_ROOT, CAMPAIGNS_PATH } from "ExtensionV2/ExtensionV2";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";

export const CampaignLaunchConfirmationModal = ({
  campaignSubmitError,
  currentSite,
  dispatch,
  isLoading,
  onClose,
  onResubmit,
  state
}: {
  campaignSubmitError: string;
  currentSite: Site;
  dispatch: React.Dispatch<CampaignSetupAction>;
  isLoading: boolean;
  onClose: () => void;
  onResubmit: (args: CreateGoogleAdsCampaignArgs) => void;
  state: CampaignSetupState;
}): JSX.Element => {
  const { siteAlias } = useSessionSite();

  const { currentReviewCampaignIndex, campaigns } = state;
  const [currentCampaign] = getCurrentCampaignAndSelectors(state);

  const {
    args: createCampaignArgs,
    isLoading: createCampaignArgsLoading,
    error: createCampaignArgsError
  } = useCreateCampaignArgs(
    currentSite,
    state,
    true /* allowPartialFailures */
  );

  const unlaunchedCampaignCount = campaigns.filter(
    campaign => !campaign.campaignId
  ).length;
  const navigateToNextReviewCampaign = () => {
    let nextUnlaunchedCampaignIndex = -1;

    // Look for the next unlaunched campaign, looping around if necessary
    for (
      let i = currentReviewCampaignIndex + 1;
      currentReviewCampaignIndex < campaigns.length &&
      i !== currentReviewCampaignIndex;
      i++
    ) {
      if (i >= campaigns.length) {
        // start search over from the beginning
        i = 0;
      }

      if (campaigns[i].campaignId === 0) {
        nextUnlaunchedCampaignIndex = i;
        break;
      }
    }

    if (nextUnlaunchedCampaignIndex > -1) {
      dispatch({
        name: "UpdateCurrentReviewCampaignIndex",
        data: nextUnlaunchedCampaignIndex
      });
    }
    onClose();
  };

  const navigate = useNavigate();

  const navigateToDashboard = () => {
    const lastCampaignId = campaigns[0]?.campaignId || "";
    const dashboardPath = generatePath(
      `${AMPD_ROOT}/${CAMPAIGNS_PATH}?campaign=${lastCampaignId}`,
      { siteAlias }
    );

    navigate(dashboardPath);
  };

  const loading = isLoading || createCampaignArgsLoading;
  if (campaignSubmitError.match(/Google Ads Policy Violation/i)) {
    return (
      <GooglePolicyViolationModal
        isLoading={loading}
        onClose={onClose}
        onResubmit={() => {
          if (!createCampaignArgs) {
            return;
          }
          onResubmit({
            ...createCampaignArgs,
            onCreateCampaign: reply => {
              if (reply?.campaignId) {
                dispatch({
                  name: "UpdateCampaignId",
                  data: {
                    campaignId: reply.campaignId,
                    index: state.currentReviewCampaignIndex
                  }
                });
              }
            }
          });
        }}
        policyViolationError={campaignSubmitError}
      />
    );
  } else if (campaignSubmitError.match(PAUSING_AUTOMATION_CONFIG_ERROR)) {
    return (
      <PausingAutomationConfigErrorModal
        isLoading={loading}
        navigateToNextReviewCampaign={navigateToNextReviewCampaign}
        navigateToDashboard={navigateToDashboard}
        onClose={onClose}
        pausingAutomationError={campaignSubmitError}
        unlaunchedCampaignCount={unlaunchedCampaignCount}
      />
    );
  } else if (
    !currentCampaign.campaignId &&
    (campaignSubmitError || createCampaignArgsError)
  ) {
    return (
      <GenericErrorModal
        onClose={onClose}
        errorMessage={campaignSubmitError || createCampaignArgsError}
      />
    );
  } else {
    return (
      <SuccessModal
        isLoading={loading}
        onClose={onClose}
        unlaunchedCampaignCount={unlaunchedCampaignCount}
        navigateToNextReviewCampaign={navigateToNextReviewCampaign}
        navigateToDashboard={navigateToDashboard}
        errorMessage={campaignSubmitError || createCampaignArgsError}
      />
    );
  }
};

const GenericErrorModal = ({
  errorMessage,
  onClose
}: {
  errorMessage: string;
  onClose: () => void;
}) => {
  return (
    <Modal defaultOpen={true} dimmer={false}>
      <Modal.Content>
        <Message negative>
          <p
            style={{
              whiteSpace: "pre-wrap"
            }}
          >
            {extractErrorMessage(errorMessage) ||
              "There was a problem creating your campaign."}
          </p>
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const PausingAutomationConfigErrorModal = ({
  isLoading,
  navigateToNextReviewCampaign,
  navigateToDashboard,
  onClose,
  pausingAutomationError,
  unlaunchedCampaignCount
}: {
  isLoading: boolean;
  navigateToNextReviewCampaign: () => void;
  navigateToDashboard: () => void;
  onClose: () => void;
  pausingAutomationError: string;
  unlaunchedCampaignCount: number;
}) => {
  return (
    <Modal defaultOpen={true} dimmer={false}>
      <Modal.Content>
        <Message warning style={{ whiteSpace: "pre-wrap" }}>
          {pausingAutomationError}
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          primary
          onClick={
            unlaunchedCampaignCount > 0
              ? navigateToNextReviewCampaign
              : navigateToDashboard
          }
          loading={isLoading}
        >
          {unlaunchedCampaignCount > 0
            ? "Review Next Campaign"
            : "See Campaigns"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const GooglePolicyViolationModal = ({
  isLoading,
  onClose,
  onResubmit,
  policyViolationError
}: {
  isLoading: boolean;
  onClose: () => void;
  onResubmit: () => void;
  policyViolationError: string;
}) => {
  return (
    <Modal defaultOpen={true} dimmer={false}>
      <Modal.Content>
        <h3>
          The following Google Ads Policy violations were found in your
          campaign. You can continue launching your campaign, but these keywords
          may not be added.
        </h3>
        <Message warning style={{ whiteSpace: "pre-wrap" }}>
          {policyViolationError}
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button primary onClick={onResubmit} loading={isLoading}>
          Create Campaign
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const SuccessModal = ({
  isLoading,
  onClose,
  unlaunchedCampaignCount,
  navigateToNextReviewCampaign,
  navigateToDashboard,
  errorMessage
}: {
  isLoading: boolean;
  onClose: () => void;
  unlaunchedCampaignCount: number;
  navigateToNextReviewCampaign: () => void;
  navigateToDashboard: () => void;
  // A campaign can succeed but still have errors occur in any of the followup actions
  errorMessage?: string;
}) => {
  const nextCampaignMessage =
    unlaunchedCampaignCount === 0
      ? "All campaigns have been launched."
      : `${pluralize(
          unlaunchedCampaignCount,
          "campaign",
          "campaigns"
        )} left to review and launch.`;

  return (
    <Modal defaultOpen={true} dimmer={false}>
      <Modal.Content>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {isLoading ? (
            <Loader active inline size="large" />
          ) : (
            <>
              <Icon name="check circle" size="massive" color="green" />
              <h2>Campaign Launched</h2>
              <p>{nextCampaignMessage}</p>
              {errorMessage && (
                <Message warning style={{ whiteSpace: "pre-wrap" }}>
                  {errorMessage}
                </Message>
              )}
            </>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          primary
          onClick={
            unlaunchedCampaignCount > 0
              ? navigateToNextReviewCampaign
              : navigateToDashboard
          }
          loading={isLoading}
        >
          {unlaunchedCampaignCount > 0
            ? "Review Next Campaign"
            : "See Campaigns"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
