import React, { useMemo } from "react";

import { List, Segment } from "semantic-ui-react";
import styled from "styled-components";

import { None } from "Common/utils/tsUtils";
import CampaignMaxCPCEditButton from "./CampaignMaxCPCEditButton";
import AdContentEditButton from "./AdContentEditButton";
import AmazonSearchTermsEditButton from "./AmazonSearchTermsEditButton";
import AmazonAttributionEditButton from "./AmazonAttributionEditButton";
import AmazonFinalURLEditButton from "./AmazonFinalURLEditButton";
import BidAutomationEditButton, {
  UPGRADE_FOR_BID_AUTOMATION_TOOLTIP
} from "./BidAutomationEditButton";
import PausingAutomationEditButton, {
  UPGRADE_FOR_PAUSING_AUTOMATION_TOOLTIP
} from "./PausingAutomationEditButton";
import { UpgradeButton } from "../../pages/SettingsPage/SettingsPage";
import {
  AttributionAdvertiser,
  getCustomAttributionTargetURL
} from "Common/utils/amazon";
import {
  convertMicrosToCurrencyUnit,
  getCurrencyMetricDef
} from "Common/utils/money";
import { formatMetric } from "Common/utils/metrics";
import RSAAdMockUp from "../adContentComposer/RSAAdMockUp";
import {
  AttributionBehavior,
  AutomationStatus,
  ItemizedCampaignConfiguration
} from "../../queries/useItemizedCampaignConfiguration";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";
import { roundedPercentageMetricDef } from "../MetricColumns";
import {
  useHasAmpdBidAutomationFeature,
  useHasAmpdProtectFeature,
  useHasSelfServeBilling
} from "Common/utils/featureFlags";
import { getBidAutomationStatusInfo } from "../bidAutomation/getBidAutomationStatusInfo";
import { useSessionUser } from "../../queries/useSessionUser";
import SimpleTooltip from "../SimpleTooltip";
import { extractWalmartURLInfoFromString } from "Common/utils/walmart";
import WalmartFinalURLEditButton from "./WalmartFinalURLEditButton";
import WalmartSearchTermsEditButton from "./WalmartSearchTermsEditButton";
import {
  isAmazonMarketplaceInfo,
  isWalmartMarketplaceInfo,
  MarketplaceInfo
} from "Common/utils/marketplace";
import WalmartAttributionEditButton from "ExtensionV2/components/campaignEditor/WalmartAttributionEditButton";

export const PAGE_GA_CATEGORY = "Ampd: Edit Campaign Attributes";

const EditButtonWrapper = styled.div`
  width: 1em;
`;

const EditTitle = styled.p`
  font-weight: bold;
`;

const EditableCampaignDetail = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 1em;
  h5 {
    margin: 0;
  }
`;

const getAttributionBehaviorContent = (
  marketplaceInfo: MarketplaceInfo,
  attributionBehavior: AttributionBehavior,
  attributionAdvertiser: AttributionAdvertiser | null
) => {
  if (isAmazonMarketplaceInfo(marketplaceInfo)) {
    if (
      attributionBehavior === AttributionBehavior.AMPD_ATTRIBUTION &&
      attributionAdvertiser
    ) {
      return (
        <div>
          Profile: {attributionAdvertiser.profileName} [
          {attributionAdvertiser.profileIdStr}]
          <br />
          Advertiser: {attributionAdvertiser.advertiserName}
          {attributionAdvertiser.isDeprecated ? (
            <strong>
              {" "}
              <em>(deprecated)</em>
            </strong>
          ) : (
            ""
          )}
        </div>
      );
    } else if (attributionBehavior === AttributionBehavior.CUSTOM_ATTRIBUTION) {
      return (
        <div>
          <p>
            Go to the Attribution dashboard in Amazon to examine the attribution
            of your sales.
          </p>
          <p>
            Unfortunately, revenue and ACOS values will not be available in the
            Ampd dashboard.
          </p>
        </div>
      );
    } else if (attributionBehavior === AttributionBehavior.NO_ATTRIBUTION) {
      return (
        <div>
          Sales will not be attributed to your ads in the Amazon Attribution
          dashboard or the Ampd dashboard, so no revenue or ACOS values will be
          available.
        </div>
      );
    }
  } else if (isWalmartMarketplaceInfo(marketplaceInfo)) {
    if (
      attributionBehavior ===
      AttributionBehavior.AMPD_WALMART_PARALLEL_ATTRIBUTION
    ) {
      return "Parallel tracking";
    } else if (
      attributionBehavior ===
      AttributionBehavior.AMPD_WALMART_REDIRECT_ATTRIBUTION
    ) {
      return "Redirect";
    } else {
      return "Not enabled";
    }
  }

  return "N/A";
};

const EditableCampaignConfiguration: React.FC<{
  siteAlias: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
}> = ({
  siteAlias,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration
}) => {
  const {
    fullAmpdConfiguration,
    currencyCode,
    marketplaceInfo,
    attributionAdvertiser,
    attributionBehavior,
    attributionCustomTags,
    descriptions,
    finalURL,
    headlines,
    trackOrganicRankings,
    searchTerms,
    campaignMaxCPCMicros,
    pausingAutomationEnabled,
    pausingAutomationCostThresholdMicros,
    pausingAutomationAACOSThresholdPoints,
    pausingAutomationReevaluatePausedKeywords,
    pausingAutomationMinNumActiveKeywords,
    bidAutomationStatus,
    isAmpdCampaignForAmazon,
    isAmpdCampaignForWalmart
  } = itemizedCampaignConfiguration;
  const bidAutomationStatusInfo = getBidAutomationStatusInfo(
    fullAmpdConfiguration
  );

  const hasAmpdProtectFeature = useHasAmpdProtectFeature();
  const hasAmpdBidAutomationFeature = useHasAmpdBidAutomationFeature();
  const hasSelfServeBilling = useHasSelfServeBilling();
  const { isCurrentSiteAdmin } = useSessionUser();

  const currencyMetricDef = getCurrencyMetricDef(currencyCode);

  const maxCPCDisplayValue = !campaignMaxCPCMicros
    ? "No maximum"
    : formatMetric(
        currencyMetricDef,
        convertMicrosToCurrencyUnit(campaignMaxCPCMicros)
      );

  const pausingAutomationCostThresholdDisplayValue = !pausingAutomationCostThresholdMicros
    ? ""
    : formatMetric(
        currencyMetricDef,
        convertMicrosToCurrencyUnit(pausingAutomationCostThresholdMicros)
      );

  const pausingAutomationAACOSThresholdDisplayValue = !pausingAutomationAACOSThresholdPoints
    ? ""
    : formatMetric(
        roundedPercentageMetricDef,
        pausingAutomationAACOSThresholdPoints / 100
      );

  let targetURL: string | null = null;
  if (isAmpdCampaignForAmazon) {
    targetURL =
      attributionBehavior !== AttributionBehavior.CUSTOM_ATTRIBUTION
        ? finalURL
        : getCustomAttributionTargetURL(finalURL, attributionCustomTags);
  } else if (isAmpdCampaignForWalmart) {
    const walmartURLInfo = extractWalmartURLInfoFromString(finalURL);
    targetURL = walmartURLInfo.effectiveURL;
  }

  const attributionBehaviorContent = getAttributionBehaviorContent(
    marketplaceInfo,
    attributionBehavior,
    attributionAdvertiser
  );

  const adPreview = useMemo(() => {
    return (
      <RSAAdMockUp
        productURL={finalURL}
        headlines={headlines.map(h => h.text) || []}
        descriptions={descriptions.map(d => d.text) || []}
        style={{ width: "calc(100% - 20px)", minHeight: 160 }}
        initiallyPaused={false}
      />
    );
  }, [descriptions, finalURL, headlines]);

  return (
    <Segment.Group compact style={{ width: "100%" }}>
      <Segment>
        <EditableCampaignDetail>
          <div>
            <AdContentEditButton
              siteAlias={siteAlias}
              itemizedCampaignConfiguration={itemizedCampaignConfiguration}
            />
            <EditTitle
              style={{
                display: "inline-block",
                marginLeft: "1em"
              }}
            >
              Ad Copy:
            </EditTitle>
          </div>
          <div style={{ maxWidth: "100%", overflowX: "auto" }}>{adPreview}</div>
        </EditableCampaignDetail>
      </Segment>

      <Segment>
        <EditableCampaignDetail>
          <EditButtonWrapper>
            {isAmpdCampaignForAmazon && (
              <AmazonFinalURLEditButton
                siteAlias={siteAlias}
                gaCategory={PAGE_GA_CATEGORY}
                itemizedCampaignConfiguration={itemizedCampaignConfiguration}
                refetchCampaignConfiguration={refetchCampaignConfiguration}
              />
            )}
            {isAmpdCampaignForWalmart && (
              <WalmartFinalURLEditButton
                siteAlias={siteAlias}
                gaCategory={PAGE_GA_CATEGORY}
                itemizedCampaignConfiguration={itemizedCampaignConfiguration}
                refetchCampaignConfiguration={refetchCampaignConfiguration}
              />
            )}
          </EditButtonWrapper>
          <div>
            <EditTitle>Target URL for Ad:</EditTitle>
          </div>

          <SimpleTooltip tooltip={<ListURLSearchParams url={targetURL} />}>
            <a
              style={{ overflowWrap: "anywhere" }}
              href={targetURL || undefined}
              target="_blank"
              rel="noopener noreferrer"
            >
              {targetURL}
            </a>
          </SimpleTooltip>
        </EditableCampaignDetail>
      </Segment>

      {isAmpdCampaignForAmazon && (
        <Segment>
          <EditableCampaignDetail>
            <EditButtonWrapper>
              <AmazonAttributionEditButton
                siteAlias={siteAlias}
                gaCategory={PAGE_GA_CATEGORY}
                itemizedCampaignConfiguration={itemizedCampaignConfiguration}
                refetchCampaignConfiguration={refetchCampaignConfiguration}
              />
            </EditButtonWrapper>
            <div>
              <EditTitle>Attribution Behavior:</EditTitle>
            </div>

            {attributionBehaviorContent}
          </EditableCampaignDetail>
        </Segment>
      )}

      {isAmpdCampaignForWalmart && (
        <Segment>
          <EditableCampaignDetail>
            <EditButtonWrapper>
              <WalmartAttributionEditButton
                siteAlias={siteAlias}
                gaCategory={PAGE_GA_CATEGORY}
                itemizedCampaignConfiguration={itemizedCampaignConfiguration}
                refetchCampaignConfiguration={refetchCampaignConfiguration}
              />
            </EditButtonWrapper>
            <div>
              <EditTitle>Attribution Behavior:</EditTitle>
            </div>

            {attributionBehaviorContent}
          </EditableCampaignDetail>
        </Segment>
      )}

      <Segment>
        <EditableCampaignDetail>
          <EditButtonWrapper>
            <BidAutomationEditButton
              siteAlias={siteAlias}
              gaCategory={PAGE_GA_CATEGORY}
              itemizedCampaignConfiguration={itemizedCampaignConfiguration}
              refetchCampaignConfiguration={refetchCampaignConfiguration}
              disabled={!hasAmpdBidAutomationFeature}
            />
          </EditButtonWrapper>

          <div>
            <EditTitle>Ampd Bid Automation:</EditTitle>
          </div>
          <div>{bidAutomationStatusInfo.statusText}</div>
          {!hasAmpdBidAutomationFeature &&
            hasSelfServeBilling &&
            isCurrentSiteAdmin && (
              <SimpleTooltip tooltip={UPGRADE_FOR_BID_AUTOMATION_TOOLTIP}>
                <div style={{ marginLeft: "auto" }}>
                  <UpgradeButton
                    siteAlias={siteAlias}
                    gaLocation="Edit Ampd Bid Automation"
                    label="Upgrade to Ampd Automation"
                    compact={true}
                  />
                </div>
              </SimpleTooltip>
            )}
        </EditableCampaignDetail>
      </Segment>

      <Segment>
        <EditableCampaignDetail>
          <EditButtonWrapper>
            <CampaignMaxCPCEditButton
              siteAlias={siteAlias}
              gaCategory={PAGE_GA_CATEGORY}
              itemizedCampaignConfiguration={itemizedCampaignConfiguration}
              refetchCampaignConfiguration={refetchCampaignConfiguration}
              disabled={
                hasAmpdBidAutomationFeature &&
                bidAutomationStatus === AutomationStatus.ENABLED
              }
            />
          </EditButtonWrapper>

          <div>
            <EditTitle>Maximum Cost-Per-Click Bid: </EditTitle>
          </div>
          <div>
            <p>{maxCPCDisplayValue}</p>
          </div>
        </EditableCampaignDetail>
      </Segment>

      <Segment>
        <EditableCampaignDetail>
          <EditButtonWrapper>
            <PausingAutomationEditButton
              siteAlias={siteAlias}
              gaCategory={PAGE_GA_CATEGORY}
              itemizedCampaignConfiguration={itemizedCampaignConfiguration}
              refetchCampaignConfiguration={refetchCampaignConfiguration}
              disabled={!hasAmpdProtectFeature}
            />
          </EditButtonWrapper>

          <div>
            <EditTitle>Ampd Protection: </EditTitle>
          </div>
          <div>
            {!pausingAutomationEnabled ? (
              <p>Not turned on</p>
            ) : (
              <div style={{ marginTop: 0, marginBottom: 0 }}>
                Cost threshold: {pausingAutomationCostThresholdDisplayValue}
                <br />
                AACOS threshold: {pausingAutomationAACOSThresholdDisplayValue}
                {pausingAutomationReevaluatePausedKeywords &&
                  " (re-evaluate after pausing)"}
                <br />
                Minimum number of active keywords:{" "}
                {pausingAutomationMinNumActiveKeywords}
              </div>
            )}
          </div>
          {!hasAmpdProtectFeature && hasSelfServeBilling && isCurrentSiteAdmin && (
            <SimpleTooltip tooltip={UPGRADE_FOR_PAUSING_AUTOMATION_TOOLTIP}>
              <div style={{ marginLeft: "auto" }}>
                <UpgradeButton
                  siteAlias={siteAlias}
                  gaLocation="Edit Ampd Protection"
                  label="Upgrade to Ampd Automation"
                  compact={true}
                />
              </div>
            </SimpleTooltip>
          )}
        </EditableCampaignDetail>
      </Segment>

      {isAmpdCampaignForAmazon && (
        <Segment>
          <EditableCampaignDetail>
            <EditButtonWrapper>
              <AmazonSearchTermsEditButton
                siteAlias={siteAlias}
                gaCategory={PAGE_GA_CATEGORY}
                itemizedCampaignConfiguration={itemizedCampaignConfiguration}
                refetchCampaignConfiguration={refetchCampaignConfiguration}
              />
            </EditButtonWrapper>
            <div>
              <EditTitle>Track Search Term Rankings:</EditTitle>
            </div>

            {trackOrganicRankings ? (
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                {searchTerms &&
                  searchTerms.map(term => <li key={term}>{term}</li>)}
              </ul>
            ) : (
              <p>Not turned on</p>
            )}
          </EditableCampaignDetail>
        </Segment>
      )}

      {isAmpdCampaignForWalmart && (
        <Segment>
          <EditableCampaignDetail>
            <EditButtonWrapper>
              <WalmartSearchTermsEditButton
                siteAlias={siteAlias}
                gaCategory={PAGE_GA_CATEGORY}
                itemizedCampaignConfiguration={itemizedCampaignConfiguration}
                refetchCampaignConfiguration={refetchCampaignConfiguration}
              />
            </EditButtonWrapper>
            <div>
              <EditTitle>Track Search Term Rankings:</EditTitle>
            </div>

            <ul style={{ marginTop: 0, marginBottom: 0 }}>
              {searchTerms &&
                searchTerms.map(term => <li key={term}>{term}</li>)}
            </ul>
          </EditableCampaignDetail>
        </Segment>
      )}
    </Segment.Group>
  );
};

const ListURLSearchParams: React.FC<{ url: string | None }> = ({ url }) => {
  if (!url) {
    return null;
  }

  let searchParams;
  try {
    searchParams = new URL(url).searchParams;
  } catch (e) {
    return null;
  }

  return (
    <List>
      {Array.from(searchParams).map(([name, value], index) => (
        <List.Item key={index}>
          {name === "facet" ? (
            <div>
              facets:
              <br />
              <List>
                {value.split("||").map((facet, facetIndex) => (
                  <List.Item key={facetIndex}>'{facet}'</List.Item>
                ))}
              </List>
            </div>
          ) : (
            `${name} = '${value}'`
          )}
        </List.Item>
      ))}
    </List>
  );
};

export default EditableCampaignConfiguration;
