// Generates a report of the specified report type by building up a hierarchical
// report data object with data for each site, for each campaign in each site,
// and, optionally, for each keyword in each campaign.
import _ from "lodash";
import Immutable from "immutable";
import { saveAs } from "file-saver";

import { determineAmazonASINForCampaign } from "../../redux/audit";
import {
  queryGoogleAdsCampaignKeywordMetrics,
  queryGoogleAdsCampaignKeywordObjects,
  queryGoogleAdsCampaignMetrics
} from "../../grpc/queryGoogleAds";
import { getCurrencyMetricDef } from "Common/utils/money";
import {
  accumulateMetrics,
  COLUMN_TITLES,
  EXCEL_METRIC_FORMATS,
  REVENUE_CURRENCY_COLUMNS,
  COMPATIBLE_CURRENCIES_ONLY_COLUMNS,
  formatColumnMetric,
  exportFormattedValue,
  getMetricsAccumulator
} from "../MetricColumns";

import { AMPD_REPORT_QUERY } from "../../graphql";
import {
  ALL_REPORT_CAMPAIGN_COLUMNS,
  ALL_REPORT_KEYWORD_COLUMNS
} from "./MetricSelectorTable";

export const REPORT_TYPES = {
  CAMPAIGN_CSV: "UNFORMATTED_CAMPAIGN_METRICS_CSV",
  KEYWORD_CSV: "UNFORMATTED_KEYWORD_METRICS_CSV",
  PDF: "SUMMARY_PDF",
  EXCEL: "EXCEL",

  AMPD_PRO_OP_CAMPAIGN_IMPRESSIONS_SHARE_CSV: "CAMPAIGN_IMPRESSIONS_SHARE_CSV",
  AMPD_PRO_OP_KEYWORD_IMPRESSIONS_SHARE_CSV: "KEYWORD_IMPRESSIONS_SHARE_CSV"
};

export async function generateReport(
  apolloClient,
  queryPromiseLimiter,
  siteAliases,
  reportType,
  startDates,
  endDates,
  dateRangeNames,
  reportSummaryColumns,
  campaignInfoList,
  includeEnabledKeywords,
  includePausedKeywords,
  setProgressCount,
  setProgressIndex,
  setProgressLabel
) {
  let doCollectForRangeMetrics = false;
  let doCollectByDayMetrics = false;
  let doCollectCampaignMetrics = false;
  let doCollectKeywordMetrics = false;

  if (reportType === REPORT_TYPES.EXCEL) {
    doCollectCampaignMetrics = true;
    doCollectKeywordMetrics = includeEnabledKeywords || includePausedKeywords;
    doCollectForRangeMetrics = true;
    doCollectByDayMetrics = true;
  } else if (reportType === REPORT_TYPES.CAMPAIGN_CSV) {
    doCollectCampaignMetrics = true;
    doCollectByDayMetrics = true;
    doCollectKeywordMetrics = false;
  } else if (reportType === REPORT_TYPES.KEYWORD_CSV) {
    doCollectKeywordMetrics = true;
    includeEnabledKeywords = true;
    doCollectByDayMetrics = true;
  } else if (reportType === REPORT_TYPES.PDF) {
    doCollectCampaignMetrics = true;
    doCollectKeywordMetrics = includeEnabledKeywords || includePausedKeywords;
    doCollectForRangeMetrics = true;
  }

  const campaignForRangeMetricsMaps = Array(startDates.length).fill(
    Immutable.Map()
  );
  const campaignByDayMetricsMaps = Array(startDates.length).fill(
    Immutable.Map()
  );
  const campaignKeywordForRangeMetricsMaps = Array(startDates.length).fill(
    Immutable.Map()
  );
  const campaignKeywordByDayMetricsMaps = Array(startDates.length).fill(
    Immutable.Map()
  );

  setProgressLabel("Generating report");
  setProgressIndex(0);

  let promises = [];

  if (doCollectCampaignMetrics) {
    const collectCampaignMetrics = (queryByDay, metricsMaps) =>
      siteAliases.flatMap(siteAlias =>
        startDates.map((startDate, index) =>
          queryPromiseLimiter(async () => {
            const endDate = endDates[index];

            const { siteMetricsMap } = await queryGoogleAdsCampaignMetrics(
              apolloClient,
              siteAlias,
              startDate,
              endDate,
              queryByDay
            );

            metricsMaps[index] = metricsMaps[index].merge(siteMetricsMap);

            setProgressIndex(counter => counter + 1);
          })
        )
      );

    if (doCollectForRangeMetrics) {
      promises.push(
        ...collectCampaignMetrics(false, campaignForRangeMetricsMaps)
      );
    }

    if (doCollectByDayMetrics) {
      promises.push(...collectCampaignMetrics(true, campaignByDayMetricsMaps));
    }
  }

  let campaignKeywordObjectsMap = Immutable.Map();
  if (doCollectKeywordMetrics) {
    const collectKeywordMetrics = (queryByDay, metricsMaps) =>
      siteAliases.flatMap(siteAlias =>
        startDates.map((startDate, index) =>
          queryPromiseLimiter(async () => {
            const endDate = endDates[index];

            const siteMetricsMap = await queryGoogleAdsCampaignKeywordMetrics(
              apolloClient,
              siteAlias,
              startDate,
              endDate,
              queryByDay,
              campaignInfoList
            );

            metricsMaps[index] = metricsMaps[index].merge(siteMetricsMap);

            setProgressIndex(counter => counter + 1);
          })
        )
      );

    if (doCollectForRangeMetrics) {
      promises.push(
        ...collectKeywordMetrics(false, campaignKeywordForRangeMetricsMaps)
      );
    }

    if (doCollectByDayMetrics) {
      promises.push(
        ...collectKeywordMetrics(true, campaignKeywordByDayMetricsMaps)
      );
    }

    const collectKeywordObjects = () =>
      siteAliases.map(siteAlias =>
        queryPromiseLimiter(async () => {
          const siteObjectsMap = await queryGoogleAdsCampaignKeywordObjects(
            apolloClient,
            siteAlias,
            campaignInfoList
          );

          campaignKeywordObjectsMap = campaignKeywordObjectsMap.merge(
            siteObjectsMap
          );

          setProgressIndex(counter => counter + 1);
        })
      );

    promises.push(...collectKeywordObjects());
  }

  setProgressCount(promises.length + 1);

  await Promise.all(promises);

  const totalDataList = [];

  campaignInfoList.forEach(campaignInfo => {
    const campaignData = buildCampaignData(
      campaignInfo,
      campaignForRangeMetricsMaps,
      campaignByDayMetricsMaps,
      campaignKeywordObjectsMap,
      campaignKeywordForRangeMetricsMaps,
      campaignKeywordByDayMetricsMaps,
      includeEnabledKeywords,
      includePausedKeywords
    );

    if (campaignData) {
      // Add the campaignData under its corresponding totalData object and siteData object.
      const totalData = getTotalDataForCampaign(
        totalDataList,
        campaignInfo,
        campaignInfoList,
        campaignForRangeMetricsMaps
      );

      const siteData = getSiteDataForCampaign(
        totalData,
        totalData.siteDataList,
        campaignInfo,
        campaignInfoList,
        campaignForRangeMetricsMaps
      );

      siteData.campaignDataList.push(campaignData);
    }

    return;
  });

  return apolloClient
    .query({
      query: AMPD_REPORT_QUERY,
      fetchPolicy: "no-cache",
      variables: {
        reportType: reportType,
        includePausedKeywords,
        reportData: {
          reportTitle: "Ampd Amazon Performance Report",
          reportFilename: `ampd_report_${_.min(startDates)}_${_.max(endDates)}`,
          startDates: startDates,
          endDates: endDates,
          dateRangeNames: dateRangeNames,
          campaignMetricInfos: ALL_REPORT_CAMPAIGN_COLUMNS.map(col => ({
            name: col,
            title: COLUMN_TITLES[col],
            excelFormat: EXCEL_METRIC_FORMATS[col],
            useRevenueCurrency: !!REVENUE_CURRENCY_COLUMNS[col],
            checked: reportSummaryColumns.includes(col)
          })),
          keywordMetricInfos: doCollectKeywordMetrics
            ? ALL_REPORT_KEYWORD_COLUMNS.map(col => ({
                name: col,
                title: COLUMN_TITLES[col],
                excelFormat: EXCEL_METRIC_FORMATS[col],
                useRevenueCurrency: !!REVENUE_CURRENCY_COLUMNS[col],
                checked: reportSummaryColumns.includes(col)
              }))
            : [],
          totalDataList: totalDataList
        }
      }
    })
    .then(({ data: { ampdReport } }) => {
      (ampdReport?.exports || []).forEach(exportInfo => {
        let blobParts = null;
        if (exportInfo.mimeType === "text/csv") {
          blobParts = [exportInfo.data];
        } else if (exportInfo.mimeType === "application/pdf") {
          const data = exportInfo.data;
          const array = new Uint8Array(data.length);

          for (let i = 0; i < array.length; ++i) {
            array.fill(data.charCodeAt(i), i, i + 1);
          }

          blobParts = [array.buffer];
        } else if (
          exportInfo.mimeType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const b64Data = exportInfo.data;
          const data = atob(b64Data);
          const array = new Uint8Array(data.length);

          for (let i = 0; i < array.length; ++i) {
            array.fill(data.charCodeAt(i), i, i + 1);
          }

          blobParts = [array.buffer];
        } else {
          console.error(
            `Unsupported MIME type: ${exportInfo.mimeType}, filename: ${exportInfo.filename}`
          );
        }

        if (blobParts) {
          const outputBlob = new Blob(blobParts, {
            type: exportInfo.mimeType
          });
          saveAs(outputBlob, exportInfo.filename);
        }
      });

      return;
    });
}

// Builds and returns a campaign data object for the specified campaign, its
// metrics, and any included keyword objects/metrics.
function buildCampaignData(
  campaignInfo,
  campaignForRangeMetricsMaps,
  campaignByDayMetricsMaps,
  campaignKeywordObjectsMap,
  campaignKeywordForRangeMetricsMaps,
  campaignKeywordByDayMetricsMaps,
  includeEnabledKeywords,
  includePausedKeywords
) {
  if (!campaignInfo.campaign) {
    return null;
  }

  const campaignId = String(campaignInfo.campaignId);

  const campaignMetricsList = campaignForRangeMetricsMaps.map(
    (campaignForRangeMetricsMap, index) => {
      const campaignByDayMetricsMap = campaignByDayMetricsMaps[index];
      const [forRangeMetrics, byDayMetricsList] = findMetricsForCampaign(
        [campaignForRangeMetricsMap, campaignByDayMetricsMap],
        campaignId
      );

      // Use any metrics object to (re-)define the campaign info's currencies.
      campaignInfo.costCurrencyCode =
        forRangeMetrics?.costCurrencyCode ||
        byDayMetricsList[0]?.costCurrencyCode ||
        campaignInfo.costCurrencyCode;
      campaignInfo.revenueCurrencyCode =
        forRangeMetrics?.revenueCurrencyCode ||
        byDayMetricsList[0]?.revenueCurrencyCode ||
        campaignInfo.revenueCurrencyCode;

      const formattedCampaignMetrics = formatReportMetrics(
        ALL_REPORT_CAMPAIGN_COLUMNS,
        forRangeMetrics,
        byDayMetricsList,
        campaignInfo.costCurrencyCode,
        campaignInfo.revenueCurrencyCode
      );

      return formattedCampaignMetrics;
    }
  );

  const campaignKeywords = campaignKeywordObjectsMap.get(campaignId) || [];

  const keywordDataList = [];
  campaignKeywords.forEach(keyword => {
    if (!includeEnabledKeywords && keyword.status === "ENABLED") {
      return;
    }
    if (!includePausedKeywords && keyword.status === "PAUSED") {
      return;
    }

    const keywordMetricsList = campaignKeywordForRangeMetricsMaps.map(
      (campaignKeywordForRangeMetricsMap, index) => {
        const campaignKeywordByDayMetricsMap =
          campaignKeywordByDayMetricsMaps[index];
        const [forRangeMetrics, byDayMetricsList] = findMetricsForCampaign(
          [campaignKeywordForRangeMetricsMap, campaignKeywordByDayMetricsMap],
          campaignId,
          metrics =>
            metrics.adGroupId === keyword.adGroupId &&
            metrics.criteriaId === keyword.criteriaId
        );

        const formattedKeywordMetrics = formatReportMetrics(
          ALL_REPORT_KEYWORD_COLUMNS,
          forRangeMetrics,
          byDayMetricsList,
          campaignInfo.costCurrencyCode,
          campaignInfo.revenueCurrencyCode
        );

        return formattedKeywordMetrics;
      }
    );

    keywordDataList.push({
      adGroupId: keyword.adGroupId,
      criteriaId: keyword.criteriaId,
      keywordText: keyword.text,
      keywordStatus: keyword.status,
      keywordMetricsList: keywordMetricsList
    });
  });

  const asin = determineAmazonASINForCampaign(campaignInfo);

  const campaignData = {
    customerId: campaignInfo.campaign.customerId,
    campaignId: campaignInfo.campaignId,
    campaignName: campaignInfo.campaign.name,
    campaignStatus: campaignInfo.campaign.status,
    asin: asin,
    campaignMetricsList: campaignMetricsList,
    keywordDataList: keywordDataList
  };

  return campaignData;
}

// Find the totalData object that the corresponds to the specified campaignInfo
// by the campaign's currencyCode.  If it is not found in the specified list of
// totalData objects, then create it, add it to the list, generate its
// metrics by accumulating the metrics from all campaignInfos with the same
// currencyCode, and return it.
function getTotalDataForCampaign(
  totalDataList,
  campaignInfo,
  campaignInfoList,
  campaignMetricsMaps
) {
  const costCurrencyCode = campaignInfo.costCurrencyCode;
  const revenueCurrencyCode = campaignInfo.revenueCurrencyCode;

  let totalData = _.find(
    totalDataList,
    totalData =>
      totalData.costCurrencyCode === costCurrencyCode &&
      totalData.revenueCurrencyCode === revenueCurrencyCode
  );
  if (totalData) {
    return totalData;
  }

  const totalMetricsList = campaignMetricsMaps.map(campaignMetricsMap => {
    const totalMetrics = getMetricsAccumulator();
    const totalCampaignInfos = [];

    campaignInfoList.forEach(currencyCampaignInfo => {
      if (
        currencyCampaignInfo.costCurrencyCode === costCurrencyCode &&
        currencyCampaignInfo.revenueCurrencyCode === revenueCurrencyCode
      ) {
        totalCampaignInfos.push(currencyCampaignInfo);

        const [forRangeMetrics] = findMetricsForCampaign(
          [campaignMetricsMap],
          String(currencyCampaignInfo.campaignId)
        );

        accumulateMetrics(totalMetrics, forRangeMetrics);
      }
    });

    const formattedCurrencyMetrics = formatReportMetrics(
      ALL_REPORT_CAMPAIGN_COLUMNS,
      totalMetrics,
      [],
      costCurrencyCode,
      revenueCurrencyCode
    );

    return formattedCurrencyMetrics;
  });

  totalData = {
    totalTitle:
      costCurrencyCode === revenueCurrencyCode
        ? costCurrencyCode
        : `${costCurrencyCode},${revenueCurrencyCode}`,
    costCurrencyCode: costCurrencyCode,
    revenueCurrencyCode: revenueCurrencyCode,
    totalMetricsList: totalMetricsList,
    siteDataList: []
  };

  totalDataList.push(totalData);

  return totalData;
}

// Find the siteData object that the corresponds to the specified campaignInfo
// by the campaign's siteAlias.  If it is not found in the specified list of
// siteData objects, then create it, add it to the list, generate its
// metrics by accumulating the metrics from all campaignInfos with the same
// siteAlias, and return it.
function getSiteDataForCampaign(
  totalData,
  siteDataList,
  campaignInfo,
  campaignInfoList,
  campaignMetricsMaps
) {
  const siteAlias = campaignInfo.siteAlias;
  const costCurrencyCode = totalData.costCurrencyCode;
  const revenueCurrencyCode = totalData.revenueCurrencyCode;

  let siteData = _.find(
    siteDataList,
    siteData => siteData.siteAlias === siteAlias
  );

  if (siteData) {
    return siteData;
  }

  const siteMetricsList = campaignMetricsMaps.map(campaignMetricsMap => {
    const siteMetrics = getMetricsAccumulator();
    const siteCampaignInfos = [];

    campaignInfoList.forEach(siteCampaignInfo => {
      if (siteCampaignInfo.siteAlias === siteAlias) {
        siteCampaignInfos.push(siteCampaignInfo);

        const [forRangeMetrics] = findMetricsForCampaign(
          [campaignMetricsMap],
          String(siteCampaignInfo.campaignId)
        );

        accumulateMetrics(siteMetrics, forRangeMetrics);
      }
    });

    const formattedSiteMetrics = formatReportMetrics(
      ALL_REPORT_CAMPAIGN_COLUMNS,
      siteMetrics,
      [],
      costCurrencyCode,
      revenueCurrencyCode
    );

    return formattedSiteMetrics;
  });

  siteData = {
    siteName: campaignInfo.siteName,
    siteAlias: campaignInfo.siteAlias,
    siteMetricsList: siteMetricsList,
    campaignDataList: []
  };

  siteDataList.push(siteData);

  return siteData;
}

// Returns the single for-range metrics object (with no date) and a list of
// by-day metrics (each with a date) found in the specified list of metrics maps,
// where each metrics object belongs to the specified campaign and passes any
// optional metricsTest callback.
function findMetricsForCampaign(metricsMaps, campaignId, metricsTest) {
  let forRangeMetrics = {};
  const byDayMetricsList = [];

  metricsMaps.forEach(metricsMap => {
    if (!metricsMap) {
      return;
    }

    const metricsForCampaign = metricsMap.get(campaignId) || [];

    metricsForCampaign.forEach(metrics => {
      if (!metricsTest || metricsTest(metrics)) {
        if (!metrics.date) {
          forRangeMetrics = metrics;
        } else {
          byDayMetricsList.push(metrics);
        }
      }
    });
  });

  return [forRangeMetrics, byDayMetricsList];
}

// Returns both formatted strings and raw numeric values for the metric values
// for the specified columns.
function formatReportMetrics(
  columns,
  forRangeMetrics,
  byDayMetricsList,
  costCurrencyCode,
  revenueCurrencyCode
) {
  const costCurrencyMetricDef = getCurrencyMetricDef(
    costCurrencyCode,
    true,
    true
  );
  const revenueCurrencyMetricDef = getCurrencyMetricDef(
    revenueCurrencyCode,
    true,
    true
  );

  let forRangeFormatted = undefined;
  let forRangeNumeric = undefined;
  if (!_.isEmpty(forRangeMetrics)) {
    forRangeFormatted = {};
    forRangeNumeric = {};
    columns.forEach(col => {
      if (
        costCurrencyCode !== revenueCurrencyCode &&
        COMPATIBLE_CURRENCIES_ONLY_COLUMNS[col]
      ) {
        forRangeFormatted[col] = " --";
        forRangeNumeric[col] = NaN;
      } else {
        const currencyMetricDef = REVENUE_CURRENCY_COLUMNS[col]
          ? revenueCurrencyMetricDef
          : costCurrencyMetricDef;

        const formattedValue = formatColumnMetric(
          col,
          forRangeMetrics,
          currencyMetricDef,
          currencyMetricDef,
          true,
          true,
          " --"
        );
        forRangeFormatted[col] = exportFormattedValue(formattedValue);
        forRangeNumeric[col] = formattedValue.rawValue;
      }
    });
  }

  const byDayFormatted = {};
  const byDayNumeric = {};

  if (!_.isEmpty(byDayMetricsList)) {
    byDayMetricsList.forEach(forDayMetrics => {
      const forDayFormatted = {};
      const forDayNumeric = {};
      columns.forEach(col => {
        if (
          costCurrencyCode !== revenueCurrencyCode &&
          COMPATIBLE_CURRENCIES_ONLY_COLUMNS[col]
        ) {
          forDayFormatted[col] = " --";
          forDayNumeric[col] = NaN;
        } else {
          const currencyMetricDef = REVENUE_CURRENCY_COLUMNS[col]
            ? revenueCurrencyMetricDef
            : costCurrencyMetricDef;

          const formattedValue = formatColumnMetric(
            col,
            forDayMetrics,
            currencyMetricDef,
            currencyMetricDef,
            true,
            true,
            " --"
          );
          forDayFormatted[col] = exportFormattedValue(formattedValue);
          forDayNumeric[col] = formattedValue.rawValue;
        }
      });

      byDayFormatted[forDayMetrics.date] = forDayFormatted;
      byDayNumeric[forDayMetrics.date] = forDayNumeric;
    });
  }

  return {
    forRangeFormatted: forRangeFormatted,
    forRangeNumeric: forRangeNumeric,
    byDayFormatted: byDayFormatted,
    byDayNumeric: byDayNumeric
  };
}
