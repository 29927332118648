import { DashboardSite } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardSession_pb";
import { BillingAccount } from "Common/proto/entity/billingAccount_pb";
import { InternalSiteDetails } from "Common/proto/entity/site_pb";
import { useSession, Site } from "./useSession";

// Returns the current site of the current session, or a blank stub object if
// there isn't one.
export const useSessionSite = (): Site => {
  const { currentSite } = useSession();
  if (currentSite) {
    return currentSite;
  }

  return {
    adwordsAccounts: [],
    adwordsCustomerIds: [],
    amazonInfo: {
      advertisingProfiles: [],
      advertisingAccounts: [],
      sellerAccounts: [],
      hasSuitableAmazonConfigurationForCreatingGoogleAdsAccount: false
    },
    walmartInfo: {
      walmartProfiles: []
    },
    billingAccountStatus: BillingAccount.Status.Option.ACTIVE,
    billingAccountType: DashboardSite.BillingAccountType.Option.UNSPECIFIED,
    clientBillingAccountCount: 0,
    clientBillingAccountMax: 0,
    facebookAccount: new DashboardSite.DashboardFacebookAccountInfo().toObject(),
    hasAdwordsAccount: false,
    siteAlias: "",
    siteContactEmail: "",
    siteCurrencyCode: "",
    siteDomainName: "",
    siteFeatures: new DashboardSite.DashboardFeatures().toObject(),
    siteId: "",
    siteName: "",
    subscription: null,
    hubSpotCompanyId: "",
    minGmv: -1,
    organizationType: InternalSiteDetails.OrganizationType.Option.UNKNOWN
  };
};
