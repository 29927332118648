import React from "react";

import { Icon, Message } from "semantic-ui-react";
import { Flex } from "@rebass/grid";

import EditLabelsButton from "./EditLabelsButton";
import GenerateReportButton from "./GenerateReportButton";
import { pluralize } from "Common/utils/strings";
import { LinkBillingAccountConfirmationModalLauncher } from "./LinkBillingAccountConfirmationModal";

// ref: core/entity/adapter/pgSiteManagerLink/pgSiteManagerLink.go:178
// TODO: update SiteManagerLinkClient to use an enum.
const NONE_BILLING_ACCOUNT_STATUS_STRING = "none";

const AccountsTableCheckedOperations = ({
  managerSiteAlias,
  clientSites,
  checkedSiteAliases,
  dateRangeStartDate,
  dateRangeEndDate,
  campaignInfoList,
  campaignObjectMap,
  siteLabelsByAlias,
  userLabels,
  nonUserLabels,
  updateClientLabels,
  onClose
}) => {
  const handleCloseClicked = () => {
    if (onClose) {
      onClose();
    }
  };

  const selectedClientSites = clientSites.filter(clientSite =>
    checkedSiteAliases.includes(clientSite.clientSiteAlias)
  );

  const linkableClientSites = selectedClientSites.filter(clientSite => {
    return (
      clientSite.billingStatusDescription === NONE_BILLING_ACCOUNT_STATUS_STRING
    );
  });

  return (
    <>
      <Message
        style={{
          marginTop: 0,
          marginBottom: 2,
          paddingTop: "0.5em",
          paddingBottom: "0.5em"
        }}
        color="blue"
      >
        <Flex
          style={{ gap: "1em" }}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Icon name="close" onClick={handleCloseClicked} />

          <EditLabelsButton
            buttonColor="blue"
            buttonLabel="Assign Accounts"
            title={`Assign Users to ${pluralize(
              checkedSiteAliases.length,
              "Account"
            )}`}
            managerSiteAlias={managerSiteAlias}
            clientSiteAliases={checkedSiteAliases}
            siteLabelsByAlias={siteLabelsByAlias}
            knownLabels={userLabels}
            updateClientLabels={updateClientLabels}
          />
          <EditLabelsButton
            buttonColor="blue"
            buttonLabel="Edit Labels"
            title={`Edit Labels on ${pluralize(
              checkedSiteAliases.length,
              "Account"
            )}`}
            managerSiteAlias={managerSiteAlias}
            clientSiteAliases={checkedSiteAliases}
            siteLabelsByAlias={siteLabelsByAlias}
            knownLabels={nonUserLabels}
            updateClientLabels={updateClientLabels}
          />
          <GenerateReportButton
            buttonColor="blue"
            managerSiteAlias={managerSiteAlias}
            clientSites={clientSites}
            siteAliasesToReport={checkedSiteAliases}
            dateRangeStartDate={dateRangeStartDate}
            dateRangeEndDate={dateRangeEndDate}
            campaignInfoList={campaignInfoList}
            campaignObjectMap={campaignObjectMap}
          />
          {linkableClientSites.length > 0 && (
            <LinkBillingAccountConfirmationModalLauncher
              linkableClientSites={linkableClientSites}
            />
          )}
        </Flex>
      </Message>
    </>
  );
};

export default AccountsTableCheckedOperations;
