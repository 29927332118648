import * as proto from "Common/utils/proto";
import { GRPCWebCallbackClient } from "Common/utils/grpc";
import { streamProcessor } from "Common/utils/grpcStreams";

import { GetCreatedGoogleAdsCampaignsRequest } from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";

// Queries for all of created Amazon product campaigns for the specified
// site alias.
export async function queryAmazonCampaigns(siteAlias) {
  const req = proto.set(new GetCreatedGoogleAdsCampaignsRequest(), {
    siteAlias
  });

  const campaignInfoList = [];

  return streamProcessor(
    GRPCWebCallbackClient.getCreatedGoogleAdsCampaigns(req),
    response =>
      campaignInfoList.push(
        {
          siteAlias
        },
        ...response.toObject().campaignInfosList.map(campaignInfo => {
          campaignInfo.siteAlias = siteAlias;

          return campaignInfo;
        })
      )
  )
    .then(() => {
      return { campaignInfoList };
    })
    .catch(err => {
      return { campaignInfoList: [], err };
    });
}
