import _ from "lodash";
import moment from "moment";

import React, { useMemo, useState } from "react";
import { Icon } from "semantic-ui-react";

import {
  CHECKED_COL_WIDTH,
  CHECKED_COL,
  ACCOUNT_NAME_COL,
  GOOGLE_ADS_COL,
  BILLING_STATUS_COL,
  CAMPAIGNS_COL,
  AACOS_RANGE_COL,
  AACOS_RANGE_MIN,
  AACOS_RANGE_MAX
} from "./AccountsTable";

import {
  defaultSortComparator,
  DataTableFreezeLeftCell,
  DataTableMetricCell,
  DataTableRowCell,
  SelectableDataTableRow
} from "../../components/AmpdDataTable";

import { pluralize } from "Common/utils/strings";
import { formatCustomerId } from "Common/utils/googleAds";
import {
  COLUMN_DATA_KEYS,
  formatMetricColumnValue,
  getMetricColumnCompareInfo,
  renderFormattedValue,
  InlineLoadingSpinner
} from "../../components/MetricColumns";

import { getClientSiteLabels } from "./AccountsTable";
import AccountsTableCampaignRow from "./AccountsTableCampaignRow";
import AccountsTableCommandRow from "./AccountsTableCommandRow";
import AccountsTableSubClientRow from "./AccountsTableSubClientRow";
import HubspotLink from "./HubspotLink";
import { TableRowErrorContainer } from "../../components/CampaignsTableRowIssues";
import SimpleTooltip from "../../components/SimpleTooltip";
import { isValidEmail } from "Common/utils/email";
import { SelectedPhrase } from "../../components/PhraseSelector";

function AccountsTableRow({
  clientSiteAlias,
  clientSiteLoading,
  clientSiteError,
  siteRowData,
  isSelectedSite,
  setSelectedSite,
  rowIndex,
  columns,
  metricsLoading,
  sortColumn,
  sortIsAscending,
  showFractions,
  showHubspotLinks,
  onCheckClick
}) {
  const [mouseDownTime, setMouseDownTime] = useState(null);

  const subClientRowDataList = siteRowData.subClientRowDataList;
  const subClientCount = _.size(subClientRowDataList);

  const subRowComponents = useMemo(() => {
    if (!isSelectedSite) {
      return null;
    }

    const sortedRowDataList = getSortedCampaigns(
      siteRowData.campaignRowDataList || [],
      sortColumn,
      sortIsAscending
    );

    const campaignCount = _.size(sortedRowDataList);
    const lastRowIndex = subClientCount + campaignCount - 1;

    return (
      <>
        <AccountsTableCommandRow
          clientSiteAlias={clientSiteAlias}
          clientSiteLoading={clientSiteLoading}
          subClientCount={subClientCount}
          campaignCount={campaignCount}
          rowIndex={rowIndex}
          columns={columns}
          isLastRow={lastRowIndex < 0}
        />
        {subClientRowDataList &&
          subClientRowDataList.map((subClientRowData, index) => {
            return (
              <AccountsTableSubClientRow
                key={subClientRowData.clientSiteAlias}
                subClientSiteAlias={subClientRowData.clientSiteAlias}
                subClientRowData={subClientRowData}
                rowIndex={rowIndex}
                columns={columns}
                isLastRow={index === lastRowIndex}
                showHubspotLinks={showHubspotLinks}
              />
            );
          })}
        {sortedRowDataList.map((campaignRowData, index) => {
          return (
            <AccountsTableCampaignRow
              key={campaignRowData.campaignId}
              clientSiteAlias={clientSiteAlias}
              clientSiteLoading={clientSiteLoading}
              campaignRowData={campaignRowData}
              rowIndex={rowIndex}
              columns={columns}
              metricsLoading={metricsLoading}
              showFractions={showFractions}
              isLastRow={index + subClientCount === lastRowIndex}
            />
          );
        })}
      </>
    );
  }, [
    isSelectedSite,
    siteRowData,
    clientSiteAlias,
    clientSiteLoading,
    metricsLoading,
    columns,
    rowIndex,
    sortColumn,
    sortIsAscending,
    showFractions,
    showHubspotLinks,
    subClientCount,
    subClientRowDataList
  ]);

  let errorStatus;
  if (clientSiteError) {
    errorStatus = "No Account Access";
  } else if (!siteRowData.googleAdsCustomerId) {
    errorStatus = "No Google Ads";
  } else if (siteRowData.googleAdsErrorDescription) {
    errorStatus = siteRowData.googleAdsErrorDescription;
  } else if (!siteRowData.hasAmazonAdvertisingAccount) {
    errorStatus = "No Amazon Advertising";
  }

  let subClientDescription;
  if (subClientCount > 0) {
    subClientDescription = pluralize(subClientCount, "sub-client");
  }

  return (
    <>
      <SelectableDataTableRow
        key={rowIndex}
        isSelected={isSelectedSite}
        keywordsOpen={isSelectedSite}
      >
        {columns.map(columnName => {
          switch (columnName) {
            case CHECKED_COL: {
              const siteLabels = getClientSiteLabels(siteRowData);
              const userSiteLabels = siteLabels.filter(label =>
                isValidEmail(label)
              );
              const nonUserSiteLabels = siteLabels.filter(
                label => !isValidEmail(label)
              );
              let nonUserTooltipContent = "No labels";
              if (nonUserSiteLabels.length > 0) {
                nonUserTooltipContent = (
                  <span>
                    Labels:{" "}
                    {nonUserSiteLabels.map(label => (
                      <SelectedPhrase
                        key={label}
                        phrase={label}
                        canEdit={false}
                        canRemove={false}
                      />
                    ))}
                  </span>
                );
              }
              let userTooltipContent = "Unassigned";
              if (userSiteLabels.length > 0) {
                userTooltipContent = (
                  <span>
                    Assigned:{" "}
                    {userSiteLabels.map(label => (
                      <SelectedPhrase
                        key={label}
                        phrase={label}
                        canEdit={false}
                        canRemove={false}
                      />
                    ))}
                  </span>
                );
              }
              const tooltipContent = (
                <span>
                  {nonUserTooltipContent}
                  <br />
                  {userTooltipContent}
                </span>
              );

              return (
                <DataTableFreezeLeftCell
                  style={{ textAlign: "center" }}
                  key={columnName}
                  rowIndex={rowIndex}
                >
                  {onCheckClick && (
                    <SimpleTooltip
                      tooltip={tooltipContent}
                      position="right center"
                    >
                      <Icon
                        style={{ margin: "-0.3em" }}
                        name={siteRowData[columnName]}
                        onClick={onCheckClick}
                      />
                    </SimpleTooltip>
                  )}
                </DataTableFreezeLeftCell>
              );
            }
            case ACCOUNT_NAME_COL:
              return (
                <DataTableFreezeLeftCell
                  style={{ left: CHECKED_COL_WIDTH }}
                  key={columnName}
                  rowIndex={rowIndex}
                  onMouseDown={() => {
                    setMouseDownTime(Date.now());
                  }}
                  onMouseUp={() => {
                    // Customers may try to drag on the site name so they can copy it to their
                    // clipboard. This mouseup/mousedown tries to account for that by not navigating
                    // on when a long click is detected.
                    if (Date.now() - mouseDownTime > 500) {
                      return;
                    }
                    setSelectedSite(isSelectedSite ? null : clientSiteAlias);
                  }}
                >
                  <Icon
                    name={isSelectedSite ? "angle down" : "angle right"}
                    style={{ marginRight: "0.5em" }}
                  />
                  {siteRowData[columnName]}
                  {showHubspotLinks && (
                    <HubspotLink
                      siteName={siteRowData[columnName]}
                      hubspotCompanyId={siteRowData.hubSpotCompanyId}
                    />
                  )}
                </DataTableFreezeLeftCell>
              );
            case GOOGLE_ADS_COL:
              return (
                <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                  {formatCustomerId(siteRowData[columnName], "\u2011")}
                </DataTableRowCell>
              );
            case BILLING_STATUS_COL: {
              let status = siteRowData[BILLING_STATUS_COL];
              if (status === "active" && siteRowData.billingCanceledTime) {
                if (siteRowData.billingActiveUntilTime) {
                  status = `${status} until ${moment
                    .unix(siteRowData.billingActiveUntilTime.seconds)
                    .format("MMM Do YYYY")}`;
                }
                status = `${status} (canceled ${moment
                  .unix(siteRowData.billingCanceledTime.seconds)
                  .format("MMM Do YYYY")})`;
              }

              return (
                <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                  {status}
                </DataTableRowCell>
              );
            }
            case AACOS_RANGE_COL: {
              if (errorStatus) {
                return (
                  <DataTableRowCell key={columnName} rowIndex={rowIndex} />
                );
              }

              if (clientSiteLoading || metricsLoading) {
                return (
                  <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                    <InlineLoadingSpinner />
                  </DataTableRowCell>
                );
              }

              const costCurrencyCode = siteRowData.costCurrencyCode;
              const revenueCurrencyCode = siteRowData.revenueCurrencyCode;

              const formattedMinValue = formatMetricColumnValue(
                COLUMN_DATA_KEYS.aacos,
                siteRowData[AACOS_RANGE_MIN],
                costCurrencyCode,
                revenueCurrencyCode,
                showFractions
              );
              const formattedMaxValue = formatMetricColumnValue(
                COLUMN_DATA_KEYS.aacos,
                siteRowData[AACOS_RANGE_MAX],
                costCurrencyCode,
                revenueCurrencyCode,
                showFractions
              );

              return (
                <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                  {String(formattedMinValue) === String(formattedMaxValue) ? (
                    formattedMinValue
                  ) : (
                    <span>
                      {formattedMinValue}&nbsp;-&nbsp;{formattedMaxValue}
                    </span>
                  )}
                </DataTableRowCell>
              );
            }
            case CAMPAIGNS_COL: {
              return (
                <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                  {subClientDescription ? (
                    <small>
                      <em>{subClientDescription}</em>
                    </small>
                  ) : errorStatus ? (
                    <TableRowErrorContainer>
                      <small>{errorStatus}</small>
                    </TableRowErrorContainer>
                  ) : clientSiteLoading ? (
                    <InlineLoadingSpinner />
                  ) : (
                    siteRowData[columnName]
                  )}
                </DataTableRowCell>
              );
            }
            default: {
              if (errorStatus) {
                return (
                  <DataTableRowCell key={columnName} rowIndex={rowIndex} />
                );
              }

              if (clientSiteLoading || metricsLoading) {
                return (
                  <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                    <InlineLoadingSpinner />
                  </DataTableRowCell>
                );
              }

              const costCurrencyCode = siteRowData.costCurrencyCode;
              const revenueCurrencyCode = siteRowData.revenueCurrencyCode;

              const formattedValue = formatMetricColumnValue(
                columnName,
                siteRowData[columnName],
                costCurrencyCode,
                revenueCurrencyCode,
                showFractions
              );

              // If we want to show comparison annotations, we need to compile
              // some information based on any compareMetrics value stored in
              // the data object.
              const compareInfo = getMetricColumnCompareInfo(
                columnName,
                siteRowData,
                costCurrencyCode,
                revenueCurrencyCode
              );

              return (
                <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                  {renderFormattedValue(formattedValue, undefined, compareInfo)}
                </DataTableMetricCell>
              );
            }
          }
        })}
      </SelectableDataTableRow>

      {subRowComponents}
    </>
  );
}

const getSortedCampaigns = (campaignRows, sortColumn, sortIsAscending) => {
  let useSortColumn = sortColumn;
  // Since we are sorting the campaigns within the accounts table, using the account
  // column names, change target column name as appropriate.
  if (sortColumn === ACCOUNT_NAME_COL) {
    useSortColumn = COLUMN_DATA_KEYS.campaignName;
  } else if (sortColumn === AACOS_RANGE_COL) {
    useSortColumn = COLUMN_DATA_KEYS.aacos;
  } else if (sortColumn === GOOGLE_ADS_COL) {
    useSortColumn = COLUMN_DATA_KEYS.status;
  }

  const sortedCampaignRows = campaignRows.sort((a, b) => {
    let compare = defaultSortComparator(a, b, useSortColumn, sortIsAscending);

    // If the sort column values compare equal, further sort by campaignName
    // (always ascending if not the primary sort column).
    if (sortColumn !== ACCOUNT_NAME_COL && compare === 0) {
      compare = defaultSortComparator(
        a,
        b,
        COLUMN_DATA_KEYS.campaignName,
        true
      );
    }

    return compare;
  });

  return sortedCampaignRows;
};

export default AccountsTableRow;
