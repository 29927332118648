import _ from "lodash";
import Immutable from "immutable";
import moment from "moment";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useQuery } from "react-apollo";

import { fromISO8601Date } from "Common/utils/DateUtilities";
import getCompareToDates from "./getCompareToDates";
import { getCurrencyMetricDef } from "Common/utils/money";

import {
  CAMPAIGNS_AND_BUDGETS_QUERY,
  CAMPAIGN_METRICS_QUERY
} from "../graphql";

import {
  METRIC_COLUMNS,
  calculateDerivedMetrics
} from "../components/MetricColumns";
import { formatMetric } from "Common/utils/metrics";

const CAMPAIGNS_AND_BUDGETS_QUERY_ID = "campaigns and budgets";
const CAMPAIGNS_QUERY_ID = "campaigns";
const CAMPAIGN_METRICS_QUERY_ID = "campaign metrics";

function useCampaignObjects({
  refreshCount,
  siteAlias,
  currencyCode,
  hasGoogleAdsAccounts,
  hasAmazonAccount,
  queryMetricsOnly,
  dateRangeStartDate,
  dateRangeEndDate,
  compareTo,
  queryRemovedCampaigns
}) {
  const [allCampaigns, setAllCampaigns] = useState(null);
  const [campaignMetricsMap, setCampaignMetricsMap] = useState(null);
  const [compareRangeStartDate, compareRangeEndDate] = useMemo(
    () => getCompareToDates(dateRangeStartDate, dateRangeEndDate, compareTo),
    [dateRangeStartDate, dateRangeEndDate, compareTo]
  );
  const [campaignCompareMetricsMap, setCampaignCompareMetricsMap] = useState(
    null
  );

  const currencyNoCentsMetricDef = useMemo(
    () => getCurrencyMetricDef(currencyCode, false),
    [currencyCode]
  );

  const currencyWithCentsMetricDef = useMemo(
    () => getCurrencyMetricDef(currencyCode, true),
    [currencyCode]
  );

  useEffect(() => {
    setCampaignMetricsMap(null);
  }, [dateRangeStartDate, dateRangeEndDate]);

  useEffect(() => {
    setCampaignCompareMetricsMap(null);
  }, [compareRangeStartDate, compareRangeEndDate]);

  const campaignsQueryResult = useQuery(CAMPAIGNS_AND_BUDGETS_QUERY, {
    skip: !hasGoogleAdsAccounts || queryMetricsOnly,
    fetchPolicy: "no-cache",
    variables: {
      site: { siteAlias },
      endDate: moment()
        .endOf("day")
        .format("YYYY-MM-DD"),
      numWeeks: 8,
      campaignStatuses: queryRemovedCampaigns
        ? ["REMOVED"]
        : ["ENABLED", "PAUSED"],
      adGroupStatuses: ["ENABLED", "PAUSED"],
      queryId: `${CAMPAIGNS_AND_BUDGETS_QUERY_ID}:${refreshCount}`
    }
  });

  const campaignMetricsQueryResult = useQuery(CAMPAIGN_METRICS_QUERY, {
    skip: !hasGoogleAdsAccounts || !dateRangeStartDate || !dateRangeEndDate,
    variables: {
      site: { siteAlias },
      startDate: dateRangeStartDate,
      endDate: dateRangeEndDate,
      objectType: "CAMPAIGN",
      queryId: `${CAMPAIGN_METRICS_QUERY_ID}|${dateRangeStartDate}|${dateRangeEndDate}:${refreshCount}`,
      includeAmazonAttributionData: hasAmazonAccount
    }
  });

  const campaignCompareMetricsQueryResult = useQuery(CAMPAIGN_METRICS_QUERY, {
    skip:
      !hasGoogleAdsAccounts || !compareRangeStartDate || !compareRangeEndDate,
    variables: {
      site: { siteAlias },
      startDate: compareRangeStartDate,
      endDate: compareRangeEndDate,
      objectType: "CAMPAIGN",
      queryId: `${CAMPAIGN_METRICS_QUERY_ID}|${compareRangeStartDate}|${compareRangeEndDate}:${refreshCount}`,
      includeAmazonAttributionData: hasAmazonAccount
    }
  });

  useEffect(() => {
    const adwordsObjects =
      _.get(campaignsQueryResult.data, "adwordsObjects") || {};

    if (adwordsObjects.campaigns) {
      adwordsObjects.campaigns.forEach(campaign => {
        if (adwordsObjects.budgets) {
          for (const budget of adwordsObjects.budgets) {
            if (campaign.budgetId === budget.budgetId) {
              campaign.budget = budget;
            }
          }
        }

        let enhancedStatus = "Enabled";
        if (campaign.status === "PAUSED") {
          enhancedStatus = "Paused";
        } else if (campaign.status === "REMOVED") {
          enhancedStatus = "Removed";
        } else {
          if (
            campaign.campaignStartDate &&
            fromISO8601Date(campaign.campaignStartDate).isAfter(moment())
          ) {
            enhancedStatus = "Pending";

            // TODO(robert): Enable when campaignEndDate is collected and queried.
            // } else if (campaign.campaignEndDate && fromISO8601Date(campaign.campaignEndDate).isBefore(moment())) {
            //  status = "Ended";
          } else if (
            campaign.budget &&
            !isNaN(campaign.budget.budgetAmount) &&
            !isNaN(campaign.budget.recommendedBudgetAmount) &&
            campaign.budget.budgetAmount <
              campaign.budget.recommendedBudgetAmount
          ) {
            enhancedStatus = `Limited by budget (recommended budget: ${formatRecommendedBudget(
              campaign,
              currencyNoCentsMetricDef
            )})`;
          }
        }

        campaign.enhancedStatus = enhancedStatus;

        let campaignType = "Other";
        if (campaign.advertisingChannelType === "SEARCH") {
          campaignType = "Search";
          if (
            campaign.adGroupTypes &&
            campaign.adGroupTypes.includes("SEARCH_DYNAMIC_ADS")
          ) {
            campaignType = "Dynamic Search";
          }
        } else if (campaign.advertisingChannelType === "SHOPPING") {
          campaignType = "Shopping";
          if (
            campaign.adGroupTypes &&
            campaign.adGroupTypes.includes("SHOPPING_SMART_ADS")
          ) {
            campaignType = "Smart Shopping";
          }
        }

        campaign.campaignType = campaignType;
      });

      setAllCampaigns(adwordsObjects.campaigns);
    }
  }, [
    allCampaigns,
    campaignsQueryResult.data,
    campaignsQueryResult.variables,
    currencyNoCentsMetricDef
  ]);

  const [disabledCampaignIds, setDisableCampaignIds] = useState(
    Immutable.Set()
  );

  const [updatedCampaignsQuery] = useLazyQuery(CAMPAIGNS_AND_BUDGETS_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const adwordsObjects = _.get(data, "adwordsObjects") || {};

      if (adwordsObjects.campaigns && allCampaigns) {
        let updateMap = Immutable.Map();
        adwordsObjects.campaigns.forEach(campaign => {
          if (adwordsObjects.budgets) {
            for (const budget of adwordsObjects.budgets) {
              if (campaign.budgetId === budget.budgetId) {
                campaign.budget = budget;
              }
            }
          }
          updateMap = updateMap.set(campaign.campaignId, campaign);

          setDisableCampaignIds(ids => ids.remove(campaign.campaignId));
        });
        const updatedAllCampaigns = allCampaigns.map(campaign => {
          const updatedCampaign = updateMap.get(campaign.campaignId, null);
          if (updatedCampaign) {
            _.merge(campaign, updatedCampaign);
          }
          return campaign;
        });

        setAllCampaigns(updatedAllCampaigns);
      }
    }
  });
  const refetchCampaign = useCallback(
    campaignId => {
      if (campaignId) {
        updatedCampaignsQuery({
          variables: {
            site: { siteAlias },
            endDate: moment()
              .endOf("day")
              .format("YYYY-MM-DD"),
            numWeeks: 8,
            campaignStatuses: ["ENABLED", "PAUSED"],
            campaignIds: [campaignId],
            queryId: `${CAMPAIGNS_QUERY_ID}:${campaignId}:${refreshCount}`
          }
        });

        setDisableCampaignIds(ids => ids.add(campaignId));
      }
    },
    [updatedCampaignsQuery, siteAlias, refreshCount]
  );

  useEffect(() => {
    const metricsList =
      _.get(campaignMetricsQueryResult.data, "adwordsMetrics.basicMetrics") ||
      [];

    if (
      (!hasGoogleAdsAccounts || campaignMetricsQueryResult.error) &&
      !campaignMetricsMap
    ) {
      setCampaignMetricsMap(Immutable.Map());
      return;
    }

    if (
      campaignMetricsQueryResult.data &&
      !campaignMetricsQueryResult.loading &&
      campaignMetricsQueryResult.variables.startDate === dateRangeStartDate &&
      campaignMetricsQueryResult.variables.endDate === dateRangeEndDate
    ) {
      if (!campaignMetricsMap) {
        let map = Immutable.Map();
        metricsList.forEach(metrics => {
          calculateDerivedMetrics(metrics, METRIC_COLUMNS);
          map = map.set(metrics.campaignId, metrics);
        });
        setCampaignMetricsMap(map);
      }
    }
  }, [
    campaignMetricsQueryResult.data,
    campaignMetricsQueryResult.error,
    campaignMetricsQueryResult.variables,
    campaignMetricsQueryResult.loading,
    campaignMetricsMap,
    dateRangeStartDate,
    dateRangeEndDate,
    hasGoogleAdsAccounts
  ]);

  useEffect(() => {
    const metricsList =
      _.get(
        campaignCompareMetricsQueryResult.data,
        "adwordsMetrics.basicMetrics"
      ) || [];

    if (
      campaignCompareMetricsQueryResult.data &&
      !campaignCompareMetricsQueryResult.loading &&
      campaignCompareMetricsQueryResult.variables.startDate ===
        compareRangeStartDate &&
      campaignCompareMetricsQueryResult.variables.endDate ===
        compareRangeEndDate
    ) {
      if (!campaignCompareMetricsMap && compareTo) {
        let map = Immutable.Map();
        metricsList.forEach(metrics => {
          calculateDerivedMetrics(metrics, METRIC_COLUMNS);
          map = map.set(metrics.campaignId, metrics);
        });
        setCampaignCompareMetricsMap(map);
      }
    }
  }, [
    compareTo,
    campaignCompareMetricsQueryResult.data,
    campaignCompareMetricsQueryResult.variables,
    campaignCompareMetricsQueryResult.loading,
    campaignCompareMetricsMap,
    compareRangeStartDate,
    compareRangeEndDate
  ]);

  const [campaignsLoading, setCampaignsLoading] = useState(false);
  const [
    campaignCompareMetricsLoading,
    setCampaignCompareMetricsLoading
  ] = useState(false);

  useEffect(() => {
    setCampaignsLoading(
      campaignsQueryResult.loading || campaignMetricsQueryResult.loading
    );
  }, [campaignsQueryResult.loading, campaignMetricsQueryResult.loading]);

  useEffect(() => {
    setCampaignCompareMetricsLoading(campaignCompareMetricsQueryResult.loading);
  }, [campaignCompareMetricsQueryResult.loading]);

  return {
    campaignsLoading,
    campaignCompareMetricsLoading,
    allCampaigns,
    campaignMetricsMap,
    campaignCompareMetricsMap,
    refetchCampaign,
    disabledCampaignIds,
    currencyNoCentsMetricDef,
    currencyWithCentsMetricDef
  };
}

function formatRecommendedBudget(campaign, metricDef) {
  if (!campaign || !campaign.budget) {
    return "";
  }

  const value = campaign.budget.recommendedBudgetAmount;
  if (_.isNaN(value)) {
    return "";
  }

  return formatMetric(metricDef, value);
}

export default useCampaignObjects;
