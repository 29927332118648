import _ from "lodash";
import Immutable from "immutable";

import {
  ACCOUNTS_METRIC_COLUMNS,
  DAILY_BUDGET_COL,
  AACOS_RANGE_MIN,
  AACOS_RANGE_MAX,
  CAMPAIGNS_COL
} from "./AccountsTable";
import {
  COLUMN_DATA_KEYS,
  calculateDerivedMetrics,
  convertMetricColumnToUSD
} from "../../components/MetricColumns";
import { convertValueToUSD } from "Common/utils/googleAds";
import { defaultSortComparator } from "../../components/AmpdDataTable";

function getClientSiteRowData({
  clientSite = {},
  clientSiteAliases = [],
  subClientSitesByClientSiteMap,
  campaignInfoList,
  campaignObjectMap,
  campaignCompareMetricsMap,
  campaignIdsBySiteMap,
  campaignMetricsMap,
  doCompare,
  compareMetricsLoading,
  aliasToCurrency
}) {
  let subClientRowDataList;
  if (clientSite && subClientSitesByClientSiteMap) {
    const subClientSites = subClientSitesByClientSiteMap.get(
      clientSite.clientSiteAlias
    );
    if (subClientSites && subClientSites.length > 0) {
      subClientRowDataList = subClientSites
        .map(subClientSite => ({
          ...subClientSite
        }))
        .sort((a, b) =>
          defaultSortComparator(a, b, COLUMN_DATA_KEYS.clientSiteName, true)
        );
    }
  }

  // Count the number of enabled and paused campaigns
  let campaignsDesc = getCampaignCountDescription(
    clientSiteAliases,
    campaignInfoList,
    campaignObjectMap
  );

  // Initialize site metrics.
  const siteMetrics = initializeSiteMetrics();
  let siteCompareMetrics = null;
  if (doCompare) {
    siteCompareMetrics = initializeSiteMetrics();
  }

  const siteAacosValues = [];
  const siteAacosCompareValues = [];
  let siteBudget = 0;

  const campaignIds = clientSiteAliases.reduce(
    (ids, alias) => ids.concat(campaignIdsBySiteMap.get(alias)),
    []
  );
  let budgetIds = Immutable.Set();
  const campaignRowDataList = [];
  for (const campaignId of campaignIds) {
    const campaign = campaignObjectMap.get(String(campaignId));
    if (!campaign) {
      continue;
    }
    const metrics = campaignMetricsMap.get(String(campaignId));
    const compareMetrics = campaignCompareMetricsMap.get(String(campaignId));

    if (metrics) {
      calculateDerivedMetrics(metrics, [
        ...ACCOUNTS_METRIC_COLUMNS,
        COLUMN_DATA_KEYS.aacos
      ]);
      const value = metrics[COLUMN_DATA_KEYS.aacos];
      if (value) {
        siteAacosValues.push(value);
      }
    }
    if (compareMetrics) {
      calculateDerivedMetrics(compareMetrics, [
        ...ACCOUNTS_METRIC_COLUMNS,
        COLUMN_DATA_KEYS.aacos
      ]);
      const value = compareMetrics[COLUMN_DATA_KEYS.aacos];
      if (value) {
        siteAacosCompareValues.push(value);
      }
    }

    const budget = campaign.budget;
    const budgetAmount = budget?.budgetAmount;

    // Only add the budget for enabled campaigns to the site budget value, because
    // those are the only ones that are active.
    if (campaign.status === "ENABLED") {
      const budget = campaign.budget;
      if (budget && !budgetIds.has(budget.budgetId)) {
        let addAmount = budgetAmount;
        if (aliasToCurrency) {
          const campaignInfo = _.find(
            campaignInfoList,
            info => String(info.campaignId) === String(campaignId)
          );

          addAmount = convertValueToUSD({
            value: addAmount,
            currency: aliasToCurrency[campaignInfo?.siteAlias] || "USD"
          });
        }

        siteBudget += addAmount;
      }

      budgetIds = budgetIds.add(budget.budgetId);
    }

    const campaignRowData = {
      ...campaign,
      ...metrics,
      [DAILY_BUDGET_COL]: budgetAmount,
      compareMetricsLoading,
      compareMetrics
    };

    campaignRowDataList.push(campaignRowData);

    // Update site metrics.
    updateSiteMetrics(siteMetrics, metrics, !!aliasToCurrency);
    if (siteCompareMetrics) {
      updateSiteMetrics(siteCompareMetrics, compareMetrics, !!aliasToCurrency);
    }
  }

  // Finalize site metrics.
  finalizeSiteMetrics(siteMetrics, siteAacosValues, siteBudget);
  if (siteCompareMetrics) {
    finalizeSiteMetrics(siteCompareMetrics, siteAacosCompareValues, siteBudget);

    siteMetrics.compareMetricsLoading = compareMetricsLoading;
    siteMetrics.compareMetrics = siteCompareMetrics;
  }

  return {
    ...clientSite,
    ...siteMetrics,
    [CAMPAIGNS_COL]: campaignsDesc,
    campaignRowDataList,
    subClientRowDataList
  };
}

// Returns a string of the form "<enabled count> / <total count>".
function getCampaignCountDescription(
  siteAliases,
  campaignInfoList,
  campaignObjectMap
) {
  let enabledCount = 0;
  let pausedCount = 0;

  campaignInfoList.forEach(campaignInfo => {
    if (!siteAliases.includes(campaignInfo.siteAlias)) {
      return;
    }

    const campaignId = campaignInfo.campaignId;
    const campaign = campaignObjectMap.get(String(campaignId));
    if (campaign) {
      if (campaign.status === "ENABLED") {
        enabledCount += 1;
      } else if (campaign.status === "PAUSED") {
        pausedCount += 1;
      }
    }
  });

  return `${enabledCount} / ${enabledCount + pausedCount}`;
}

// Initialize site metric values.
function initializeSiteMetrics() {
  const siteMetrics = ACCOUNTS_METRIC_COLUMNS.reduce((data, column) => {
    data[column] = 0;
    return data;
  }, {});

  return siteMetrics;
}

// Update site metric values with a campaign's metric values.
function updateSiteMetrics(siteMetrics, metrics, convertToUSD) {
  if (!metrics) {
    return;
  }

  const costCurrencyCode = metrics?.costCurrencyCode;
  const revenueCurrencyCode = metrics?.revenueCurrencyCode;
  if (convertToUSD) {
    siteMetrics.costCurrencyCode = "USD";
    siteMetrics.revenueCurrencyCode = "USD";

    // Sum all site metric columns for now.  The metrics that are not themselves
    // summable but are derived from summable metrics will be calculated after
    // the loop.  Treat all null and NaNs as zeros.
    ACCOUNTS_METRIC_COLUMNS.forEach(column => {
      siteMetrics[column] += convertMetricColumnToUSD(
        column,
        metrics[column],
        costCurrencyCode || "USD",
        revenueCurrencyCode || "USD"
      );
    });
  } else {
    siteMetrics.costCurrencyCode = costCurrencyCode;
    siteMetrics.revenueCurrencyCode = revenueCurrencyCode;

    // Sum all site metric columns for now.  The metrics that are not themselves
    // summable but are derived from summable metrics will be calculated after
    // the loop.  Treat all null and NaNs as zeros.
    ACCOUNTS_METRIC_COLUMNS.forEach(column => {
      siteMetrics[column] += metrics[column] || 0;
    });
  }
}

// Finalize site metric values
function finalizeSiteMetrics(siteMetrics, aacosValues, siteBudget) {
  // Now calculate all derived site metric values.
  calculateDerivedMetrics(siteMetrics, ACCOUNTS_METRIC_COLUMNS);

  // For AACOS, we will show a range of values instead of an aggregation.
  aacosValues.sort();
  siteMetrics[AACOS_RANGE_MIN] = aacosValues[0];
  siteMetrics[AACOS_RANGE_MAX] = aacosValues[aacosValues.length - 1];
  siteMetrics[DAILY_BUDGET_COL] = siteBudget;
}

export default getClientSiteRowData;
