import _ from "lodash";

import React, { useState } from "react";
import {
  Button,
  Confirm,
  Form,
  Icon,
  Input,
  List,
  Message,
  Modal,
  Table
} from "semantic-ui-react";

import { DashboardSite } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardSession_pb";
import AuditSegment from "ExtensionV2/components/AuditSegment";
import { sendGAEvent } from "ExtensionV2/components/GA";
import { ACCOUNTS_DASHBOARD_GA_CATEGORY } from "ExtensionV2/pages/AccountsPage/AccountsPage";
import {
  getWalmartMarketplace,
  getWalmartMarketplaceInfo,
  getWalmartSearchPageURL
} from "Common/utils/walmart";
import {
  useFindWalmartItemsForBrands,
  useWalmartSearchPage
} from "ExtensionV2/queries/useWalmartSearchPage";
import { Walmart } from "Common/proto/common/walmart_pb";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { WalmartBrandsDropdown } from "ExtensionV2/components/WalmartTargetSelector";
import { extractErrorMessage } from "Common/errors/error";
import { useSession } from "ExtensionV2/queries/useSession";
import {
  UpdateSiteWalmartProfileReply,
  UpdateSiteWalmartProfileRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Walmart_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";

type WalmartProfile = DashboardSite.DashboardWalmartProfileInfo.AsObject;

export const WalmartProfileDefinition: React.FC<{
  profile?: WalmartProfile;
  profiles: Array<WalmartProfile>;
}> = ({ profile, profiles }) => {
  const { invalidateSessionQuery } = useSession();
  const { siteAlias } = useSessionSite();

  const [modalOpen, setModalOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const handleEditClick = () => {
    sendGAEvent(
      ACCOUNTS_DASHBOARD_GA_CATEGORY,
      "Click Define Walmart Profile",
      profile?.profileName || "NEW"
    );
    setModalOpen(true);
  };

  const handleDiscardClick = () => {
    sendGAEvent(
      ACCOUNTS_DASHBOARD_GA_CATEGORY,
      "Click Discard Walmart Profile",
      profile?.profileName || "NEW"
    );
    setConfirmOpen(true);
  };

  const handleDiscardWalmartProfile = async () => {
    setConfirmOpen(false);
    setErrorMessage("");

    try {
      if (profile != null) {
        await sendUpdateWalmartProfile(
          siteAlias,
          ACCOUNTS_DASHBOARD_GA_CATEGORY,
          profile.marketplace,
          profile.profileName,
          []
        );
      }

      await invalidateSessionQuery();
    } catch (e) {
      const message = extractErrorMessage(e);
      console.error(e);
      setErrorMessage(message);
    }
  };

  const handleProfileDefinitionClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      {!profile && (
        <Button primary onClick={handleEditClick}>
          {profiles.length > 0
            ? "Define Another Walmart Profile"
            : "Define Walmart Profile"}
        </Button>
      )}
      {!!profile && !confirmOpen && (
        <AuditSegment
          siteAlias={siteAlias}
          icon="check circle"
          color="green"
          title="Defined Attibution Profile"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: ".5em"
            }}
          >
            <div style={{ flexGrow: "1" }}>
              {profile.profileName}:{" "}
              <em>{profile.brandNamesList.join(", ")}</em>
            </div>
            <Button primary onClick={handleEditClick}>
              Edit
            </Button>
            <Button onClick={handleDiscardClick}>Discard</Button>
          </div>
        </AuditSegment>
      )}
      {modalOpen && (
        <WalmartProfileDefinitionModal
          profile={profile}
          profiles={profiles}
          onClose={handleProfileDefinitionClose}
        />
      )}
      {confirmOpen && (
        <Confirm
          open={confirmOpen}
          onCancel={e => {
            e.stopPropagation();
            setConfirmOpen(false);
          }}
          confirmButton={{ content: "OK" }}
          onConfirm={handleDiscardWalmartProfile}
          content={
            <div style={{ margin: "1.5rem" }}>
              <h3>Are you sure you want to discard this Walmart profile?</h3>
              <List bulleted>
                <List.Item>{profile?.profileName}</List.Item>
              </List>
            </div>
          }
        />
      )}
      {errorMessage && (
        <Message error>
          There was an error when trying to discard the Walmart profile
          definition.
        </Message>
      )}
    </>
  );
};

export const WalmartProfileDefinitionModal: React.FC<{
  profile?: WalmartProfile;
  profiles: Array<WalmartProfile>;
  onClose: (newProfileName: string, brandNames: Array<string>) => void;
}> = ({ profile, profiles, onClose }) => {
  const { invalidateSessionQuery } = useSession();
  const { siteAlias } = useSessionSite();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const marketplaceInfo = getWalmartMarketplaceInfo(
    profile?.marketplace || Walmart.Marketplace.Option.UNITED_STATES
  );
  const [editProfileName, setEditProfileName] = useState(
    profile?.profileName || ""
  );
  const [editSearchHint, setEditSearchHint] = useState("");
  const [editBrandNames, setEditBrandNames] = useState<Array<string>>(
    profile?.brandNamesList || []
  );
  const [brandHint, setBrandHint] = useState(profile?.profileName || "");

  const handleModalClose = () => {
    onClose("", []);
  };

  const brandSearchURL =
    getWalmartSearchPageURL(marketplaceInfo, brandHint, [], null, null, true) ||
    "";

  // Look for brand names by searching for the brandHint without any filter.
  const {
    data: brandSearchData,
    isFetching: brandSearchIsFetching
  } = useWalmartSearchPage(
    siteAlias,
    brandSearchURL,
    null,
    getWalmartMarketplace(marketplaceInfo)
  );

  // Look for items that belong to the current set of brand names.
  const itemSearchResults = useFindWalmartItemsForBrands(
    siteAlias,
    editBrandNames,
    getWalmartMarketplace(marketplaceInfo)
  );

  const itemInfos = itemSearchResults.flatMap((results, brandIndex) => {
    const products = results.data?.productsList;
    if (!products) {
      return [[editBrandNames[brandIndex], "", "", "(searching)..."]];
    }
    if (products.length === 0) {
      return [[editBrandNames[brandIndex], "", "", "(No products found)"]];
    }

    return products.map(product => [
      editBrandNames[brandIndex],
      product.itemId,
      product.link,
      product.title
    ]);
  });

  const handleSelectBrands = (newBrandNames: Array<string>) => {
    setEditBrandNames(newBrandNames);
  };

  const handleProfileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value !== editProfileName) {
      const newProfileName = e.currentTarget.value;
      setEditProfileName(newProfileName);
      setErrorMessage("");
      for (const otherProfile of profiles) {
        if (otherProfile.profileName === profile?.profileName) {
          continue;
        }

        if (
          _.toLower(otherProfile.profileName.trim()) ===
          _.toLower(newProfileName.trim())
        ) {
          setErrorMessage(
            `You have another profile named '${newProfileName}'.` +
              " Please enter a different name."
          );
        }
      }
    }
  };

  const handleSearchHintChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value !== editSearchHint) {
      setEditSearchHint(e.currentTarget.value);
    }
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      const elem = e.currentTarget;
      elem.blur();
    }
  };

  const handleInputBlur = () => {
    setBrandHint(editSearchHint || editProfileName);
  };

  const disableSubmit = () => {
    if (errorMessage) {
      return true;
    }

    return (
      editBrandNames.length === 0 ||
      (editProfileName === profile?.profileName &&
        _.isEqual(editBrandNames, profile?.brandNamesList))
    );
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage("");
    try {
      const newProfileName = editProfileName.trim();
      const newBrandNames = editBrandNames;

      if (profile && newProfileName != profile.profileName) {
        // Discard old profile by clearing its list of brand names.
        await sendUpdateWalmartProfile(
          siteAlias,
          ACCOUNTS_DASHBOARD_GA_CATEGORY,
          getWalmartMarketplace(marketplaceInfo),
          profile.profileName,
          []
        );
      }

      // Create or update profile with new list of brand names
      await sendUpdateWalmartProfile(
        siteAlias,
        ACCOUNTS_DASHBOARD_GA_CATEGORY,
        getWalmartMarketplace(marketplaceInfo),
        newProfileName,
        newBrandNames
      );

      await invalidateSessionQuery();

      setIsSubmitting(false);
      onClose(newProfileName, newBrandNames);
    } catch (e) {
      const message = extractErrorMessage(e);
      console.error(e);
      setErrorMessage(message);
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={true}
      onClose={handleModalClose}
      closeIcon={<Icon name="close" color="black" />}
      closeOnDimmerClick={false}
      centered={true}
      size="large"
    >
      <Modal.Header>Define Walmart Profile</Modal.Header>
      <Modal.Content>
        <Form error={!!errorMessage} autoComplete="off">
          <Form.Field required={true}>
            <label>Profile Name</label>
            <SimpleTooltip
              wide="very"
              tooltip={
                "Required. The profile name can be any text that helps" +
                " you identify this profile, especially if you" +
                " have more than one."
              }
            >
              <Input
                placeholder="Profile Name"
                value={editProfileName}
                onChange={handleProfileNameChange}
                onKeyPress={handleInputKeyPress}
                onBlur={handleInputBlur}
                autoFocus
              />
            </SimpleTooltip>
          </Form.Field>
          <Form.Field required={true}>
            <label>Brand Names</label>
            <SimpleTooltip
              wide="very"
              tooltip={
                <span>
                  <p>
                    Required. The brand names are the exact text that will be
                    used to locate your products on the Walmart website. They
                    are the values of the 'Brand' filter in Walmart search
                    results. Select the brand names that you want to belong to
                    this profile.
                  </p>
                  <p>
                    {" "}
                    The listed brand name candidates were found by searching for
                    the hint: '{editSearchHint || editProfileName}'.
                  </p>
                </span>
              }
            >
              <div>
                <WalmartBrandsDropdown
                  brandHint={brandHint}
                  brands={editBrandNames}
                  defaultPlaceholder={brandHint ? "Select brand names" : ""}
                  loading={brandSearchIsFetching}
                  onSelectBrands={handleSelectBrands}
                  brandSearchData={
                    brandSearchIsFetching ? undefined : brandSearchData
                  }
                  brandSearchIsFetching={brandSearchIsFetching}
                  upward={true}
                />
              </div>
            </SimpleTooltip>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1em",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "1em"
              }}
            >
              <div style={{ width: "20em" }} />
              <span style={{ textWrap: "nowrap" }}>
                Hint to find brand names:
              </span>
              <SimpleTooltip
                tooltip={
                  "Enter a different hint, such as the name of one of your products," +
                  " to locate some different brand name candidates."
                }
              >
                <Input
                  value={editSearchHint || editProfileName}
                  onChange={handleSearchHintChange}
                  onKeyPress={handleInputKeyPress}
                  onBlur={handleInputBlur}
                />
              </SimpleTooltip>
            </div>
          </Form.Field>
          <Form.Field disabled={itemInfos.length === 0}>
            <span>
              Sampling of Products that will be considered in brand for this
              profile:
            </span>
            <div
              style={{
                overflow: "auto",
                height: "20em",
                fontSize: "small",
                marginTop: "1em"
              }}
            >
              <Table compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Brand Name</Table.HeaderCell>
                    <Table.HeaderCell>Item ID</Table.HeaderCell>
                    <Table.HeaderCell>Product Title</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {itemInfos.map(
                    (
                      [brandName, itemID, productLink, productTitle],
                      rowIndex
                    ) => (
                      <Table.Row key={rowIndex}>
                        <Table.Cell>{brandName}</Table.Cell>
                        <Table.Cell>
                          <a
                            href={productLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {itemID}
                          </a>
                        </Table.Cell>
                        <Table.Cell>{productTitle}</Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>
            </div>
          </Form.Field>
          <Message error>
            <p>{errorMessage}</p>
          </Message>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            onClose("", []);
          }}
        >
          Cancel
        </Button>
        <Button
          primary={true}
          loading={isSubmitting}
          disabled={disableSubmit()}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

// Updates the specified walmart profile.
async function sendUpdateWalmartProfile(
  siteAlias: string,
  gaCategory: string,
  marketplace: Walmart.Marketplace.Option,
  profileName: string,
  brandNames: Array<string>
): Promise<UpdateSiteWalmartProfileReply.AsObject | null> {
  if (!siteAlias || !profileName) {
    return null;
  }

  const updateProfileReq = new UpdateSiteWalmartProfileRequest();
  updateProfileReq.setSiteAlias(siteAlias);
  updateProfileReq.setMarketplace(marketplace);
  updateProfileReq.setProfileName(profileName);
  updateProfileReq.setBrandNamesList(brandNames);

  sendGAEvent(gaCategory, "Update Walmart Profile", siteAlias, profileName);

  const reply = await GRPCWebClient.updateSiteWalmartProfile(
    updateProfileReq,
    null
  );
  return reply.toObject();
}

export default WalmartProfileDefinition;
