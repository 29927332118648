import React, { useEffect, useState } from "react";
import { Button, Icon, Message, Modal, Segment } from "semantic-ui-react";
import OneClickButton from "Common/components/OneClickButton";
import BudgetDropdown from "./BudgetDropdown";
import CampaignAttributeEditorLauncher from "./CampaignAttributeEditorLauncher";

import { sendGAEvent } from "./GA";

// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import {
  UpdateCampaignsRequest,
  UpdateCampaignAction
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { UpdateCampaignBudgetAmountAction } from "Common/proto/googleAdsPb/campaignBudget_pb";
import { roundMicrosToCurrencyMinimumUnit } from "Common/utils/googleAds";
import { extractErrorMessage } from "Common/errors/error";

export const micros = 1e6;

export default function CampaignBudgetEditButton(props) {
  const { budget, budgetIsShared, campaign } = props;

  const shouldDisplay =
    !budget || budgetIsShared ? false : campaign?.status !== "REMOVED";

  return (
    <CampaignAttributeEditorLauncher
      {...props}
      attributeName={"Campaign Budget"}
      display={shouldDisplay}
      Editor={CampaignBudgetEditor}
    />
  );
}

function CampaignBudgetEditor(props) {
  const {
    siteAlias,
    gaCategory,
    campaign,
    campaignBudgetId,
    currencyCode,
    budget,
    refetchCampaignConfiguration,
    onClose,
    onSubmit = () => {},
    onComplete = () => {},
    onFailure = () => {}
  } = props;

  const [editBudget, setEditBudget] = useState(0);
  useEffect(() => {
    setEditBudget(budget);
    setUpdateEnabled(false);
  }, [budget]);

  const [modalOpen, setModalOpen] = useState(true);
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleUpdateCampaignBudget = async () => {
    if (!campaign) {
      return;
    }

    try {
      onSubmit();
      await sendUpdateCampaignBudget(
        siteAlias,
        gaCategory,
        campaign.customerId,
        campaignBudgetId,
        editBudget,
        currencyCode
      );

      await refetchCampaignConfiguration(campaign.campaignId);

      onComplete(editBudget);

      if (onClose) {
        onClose();
      }
    } catch (e) {
      console.error(e);

      const message = extractErrorMessage(e);

      console.error(message); // log to datadog

      setErrorMessage(message);

      onFailure();
    }
  };

  const handleModalClose = () => {
    setErrorMessage(null);
    setUpdateEnabled(false);
    setModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleSetBudget = newBudget => {
    if (newBudget != null && newBudget <= 0) {
      newBudget = budget;
    }

    setEditBudget(newBudget);
    setUpdateEnabled(newBudget != null && newBudget !== budget);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      closeIcon={<Icon name="close" color="black" />}
      centered={false}
      dimmer="inverted"
    >
      <Modal.Header>Change Campaign Budget</Modal.Header>
      <Modal.Content scrolling>
        <Segment>
          <div style={{ minHeight: 300 }}>
            <BudgetDropdown
              gaCategory={gaCategory}
              siteAlias={siteAlias}
              siteLabel="product page"
              currency={currencyCode}
              budget={editBudget}
              setBudget={handleSetBudget}
              title="Daily budget for this Google Ad Campaign"
            />
          </div>
          <div style={{ paddingTop: 20 }}>
            <Message>
              <p>
                Google will bill you directly every 30 days. Google charges only
                for the clicks that drive traffic to your product page, not for
                impressions.
              </p>
            </Message>
          </div>
        </Segment>
        {!!errorMessage && <Message error>{errorMessage}</Message>}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <OneClickButton
          primary
          onClick={handleUpdateCampaignBudget}
          disabled={!updateEnabled}
        >
          Update Campaign Budget
        </OneClickButton>
      </Modal.Actions>
    </Modal>
  );
}

// Updates the campaign budget
export async function sendUpdateCampaignBudget(
  siteAlias,
  gaCategory,
  customerId,
  campaignBudgetId,
  budget,
  currencyCode
) {
  if (!siteAlias || !customerId || !campaignBudgetId || !budget) {
    return;
  }

  const action = proto.set(new UpdateCampaignAction(), {
    updateCampaignBudgetAmount: proto.set(
      new UpdateCampaignBudgetAmountAction(),
      {
        campaignBudgetId: `${campaignBudgetId}`,
        amountMicros: roundMicrosToCurrencyMinimumUnit(
          budget * micros,
          currencyCode
        )
      }
    )
  });

  sendGAEvent(
    gaCategory,
    "Update Daily Campaign Budget",
    siteAlias,
    `${budget}`
  );

  const updateReq = proto.set(new UpdateCampaignsRequest(), {
    siteAlias,
    customerId: String(customerId),
    actions: [action]
  });

  await GRPCWebClient.updateCampaigns(updateReq);
}
