import Immutable from "immutable";
import pLimit from "p-limit";

import { useEffect, useRef, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { randomString } from "Common/utils/strings";
import { queryAmazonCampaigns } from "../grpc/queryAmazonCampaigns";
import { queryGoogleAdsCampaignObjects } from "../grpc/queryGoogleAds";

// Shared limiter used for report queries so there are not too many queries from
// one user in flight at the same time.
const queryPromiseLimiterMap = {};

function useMultiSiteAmpdCampaignObjects({
  siteAliases,
  onStart,
  onLoad,
  onFinish,
  queryGroup = "campaignObjects"
}) {
  let queryPromiseLimiter = queryPromiseLimiterMap[queryGroup];
  if (!queryPromiseLimiter) {
    queryPromiseLimiter = pLimit(10);
    queryPromiseLimiterMap[queryGroup] = queryPromiseLimiter;
  }

  // The full state of the hook will be updated asynchronously as different
  // sites return from their query.  It is important that all inner loop
  // calls to 'setHookState' pass a callback instead of a value based on this
  // 'hookState' variable, because otherwise some results will be lost.
  // The 'hookState' variable will be returned on each render but any number of
  // updates could happen between renders.
  const [hookState, setHookState] = useState({
    sitesLoadingSet: Immutable.Set(),
    sitesErrorMap: Immutable.Map(),
    campaignInfoList: [],
    campaignObjectMap: Immutable.Map(),
    campaignIdsBySiteMap: Immutable.Map()
  });
  const queryIdRef = useRef(null);

  const apolloClient = useApolloClient();

  useEffect(() => {
    let mounted = true;

    // Don't continue with previous queries that haven't been started yet.
    queryPromiseLimiter.clearQueue();

    // Let's create a random id to identify this set of queries.
    const queryId = randomString(16);
    queryIdRef.current = queryId;

    // Update the hook state directly at the start.
    setHookState({
      sitesLoadingSet: Immutable.Set(siteAliases),
      sitesErrorMap: Immutable.Map(),
      campaignInfoList: [],
      campaignObjectMap: Immutable.Map(),
      campaignIdsBySiteMap: Immutable.Map()
    });
    if (siteAliases.length === 0) {
      return () => (mounted = false);
    }

    if (onStart) {
      onStart(siteAliases);
    }

    const collectCampaignObjects = () =>
      siteAliases.map(siteAlias =>
        queryPromiseLimiter(async () => {
          if (!mounted || queryIdRef.current !== queryId) {
            return;
          }

          let campaignIds = [];
          let siteCampaignObjectMap = Immutable.Map();
          const {
            campaignInfoList: siteCampaignInfoList,
            err
          } = await queryAmazonCampaigns(siteAlias);
          if (siteCampaignInfoList.length > 0) {
            campaignIds = siteCampaignInfoList
              .map(campaignInfo => campaignInfo.campaignId)
              .filter(Boolean);

            siteCampaignObjectMap = await queryGoogleAdsCampaignObjects(
              apolloClient,
              siteAlias,
              campaignIds
            );
          }

          // Update the hook state asynchronously through a callback.
          setHookState(state => {
            let {
              sitesLoadingSet,
              sitesErrorMap,
              campaignInfoList,
              campaignObjectMap,
              campaignIdsBySiteMap
            } = state;

            sitesLoadingSet = sitesLoadingSet.remove(siteAlias);
            if (err) {
              sitesErrorMap = sitesErrorMap.set(siteAlias, err);
            }
            campaignInfoList = [...campaignInfoList, ...siteCampaignInfoList];
            campaignObjectMap = campaignObjectMap.merge(siteCampaignObjectMap);
            campaignIdsBySiteMap = campaignIdsBySiteMap.set(
              siteAlias,
              campaignIds
            );

            return {
              sitesLoadingSet,
              sitesErrorMap,
              campaignInfoList,
              campaignObjectMap,
              campaignIdsBySiteMap
            };
          });

          if (mounted && onLoad) {
            onLoad({
              siteAlias,
              campaignInfoList: siteCampaignInfoList,
              campaignIds,
              error: err
            });
          }
        })
      );

    Promise.all(collectCampaignObjects())
      .then(() => {
        if (mounted && onFinish) {
          onFinish();
        }
      })
      .catch(err => {
        console.error(err);
        if (mounted && onFinish) {
          onFinish(err);
        }
      });

    return () => (mounted = false);
  }, [
    siteAliases,
    onStart,
    onLoad,
    onFinish,
    apolloClient,
    queryPromiseLimiter
  ]);

  return hookState;
}

export default useMultiSiteAmpdCampaignObjects;
